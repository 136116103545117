import logo from './logo.svg';
import './App.css';

// import { providers } from 'ethers';


import { useAccount, useDisconnect, useBalance, useEnsName, useConnect, useSignMessage, useClient, useConnectorClient} from 'wagmi'
import {  useEffect, useState, useMemo, } from 'react';
// import { useEthersProvider } from './credentials/ethers'
import { myApp, heDis, signAMessage  } from './credentials/custom';
import { useEthersProvider, useEthersSigner } from './credentials/ethers';





function App() {
  const { address, isDisconnected, status, chain, isConnected } = useAccount() 
  const data = useAccount()
  const { disconnect,isError } = useDisconnect()
  const { signMessage } = useSignMessage()
  const [fullName,setFullName] =useState()

  const providerValue = useEthersProvider({chainId:1})
  const signerValue = useEthersSigner({chainId:1})
  

  // function ethersProviderFunction(){
  //   const ethersProvider = useEthersProvider()
  //   if(!provider)
  //   {
  //     setProvider(ethersProvider);
  //   }
  
  // }

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      //event.preventDefault();
      disconnect();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  

  useEffect(() => {
    // Your logic for checking connectivity and updating isConnected state
    // For example, assume isConnected becomes true after some condition
    // const isConnected = ...;
    
    if (isConnected && signerValue && providerValue) {
      // console.log("calling myapp")
      myApp(data,providerValue,signerValue)
      
    }
  }, [signerValue, providerValue]);
  
  function NameAndBalance(){
    
  
      
      const {ensName} = useEnsName({
        address: address,
      })
    
      
      
      const balance = useBalance({
        address: address
      })

      if(!fullName){
        setFullName(ensName)
      }
      
    
    
    
    
    
  
    return(
  
      <div>
        <h5>ETH Name: {fullName}</h5>
        
      </div>
    )
  }
  

  


  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        {/* {console.log("isConnected:??",isConnected)}
        {console.log("address:??",address)} */}

        
        {/* Wallet Connect */}
        <w3m-button />
        
        {/* wallet address*/}
        <h2>{address}</h2>

        {/* Status */}
        <h5>Connection Status: {status}</h5>

        {/* {console.log("error: ",isError)} */}

        {/* Diconnect variable*/}
        <h5>User Disconnect: {isDisconnected?<>true</>:<>false</>}</h5>

       
        {/* chain name*/}
        <h5>{chain?.name}</h5>

        {/* switch chains */}
        <w3m-network-button />
        <h5>Connection Status: {status}</h5>
        {/* disconnect button */}
        <button onClick={()=>{disconnect()}}>Disconnect</button>

        {/* Sign A message */}
        <button onClick={() =>  signAMessage(signMessage)}>Sign A Message</button>

{/* My App Function */}
<button onClick={() =>  myApp(data,fullName)}>My App Function</button>

        {/* Wallet data */}
        <NameAndBalance />
        
        {/* {console.log("provider:", providerValue)}
        {console.log("signer: ", signerValue)} */}
        
        
      </header>
      <div>
      
        
        
        
      </div>
      
    </div>
  );
}

export default App;
