export const  USER_OP_SUPPORTED_ETH = {
  ABT: '0xB98d4C97425d9908E66E53A6fDf673ACcA0BE986',
  ACH: '0xEd04915c23f00A313a544955524EB7DBD823143d',
  ADX: '0xADE00C28244d5CE17D72E40330B1c318cD12B7c3',
  AGLD: '0x32353A6C91143bfd6C7d363B546e62a9A2489A20',
  AIOZ: '0x626E8036dEB333b408Be468F951bdB42433cBF18',
  ALEPH: '0x27702a26126e0B3702af63Ee09aC4d1A084EF628',
  ALI: '0x6B0b3a982b4634aC68dD83a4DBF02311cE324181',
  ALICE: '0xAC51066d7bEC65Dc4589368da368b212745d63E8',
  ALPHA: '0xa1faa113cbE53436Df28FF0aEe54275c13B40975',
  AMP: '0xfF20817765cB7f73d4bde2e66e067E58D11095C2',
  ANKR: '0x8290333ceF9e6D528dD5618Fb97a76f268f3EDD4',
  ANT: '0xa117000000f279D81A1D3cc75430fAA017FA5A2e',
  APE: '0x4d224452801ACEd8B2F0aebE155379bb5D594381',
  API3: '0x0b38210ea11411557c13457D4dA7dC6ea731B88a',
  ARB: '0xB50721BCf8d664c30412Cfbc6cf7a15145234ad1',
  ASM: '0x2565ae0385659badCada1031DB704442E1b69982',
  AUDIO: '0x18aAA7115705e8be94bfFEBDE57Af9BFc265B998',
  AXL: '0x467719aD09025FcC6cF6F8311755809d45a5E5f3',
  AXS: '0xBB0E17EF65F82Ab018d8EDd776e8DD940327B28b',
  BADGER: '0x3472A5A71965499acd81997a54BBA8D852C6E53d',
  BAL: '0xba100000625a3754423978a60c9317c58a424e3D',
  BIGTIME: '0x64Bc2cA1Be492bE7185FAA2c8835d9b824c8a194',
  BIT: '0x1A4b46696b2bB4794Eb3D4c26f1c55F9170fa4C5',
  BLUR: '0x5283D291DBCF85356A21bA090E6db59121208b44',
  BLZ: '0x5732046A883704404F284Ce41FfADd5b007FD668',
  BNT: '0x1F573D6Fb3F13d689FF844B4cE37794d79a7FF1C',
  BOND: '0x0391D2021f89DC339F60Fff84546EA23E337750f',
  BTRST: '0x799ebfABE77a6E34311eeEe9825190B9ECe32824',
  BUSD: '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
  CBETH: '0xBe9895146f7AF43049ca1c1AE358B0541Ea49704',
  CELR: '0x4F9254C83EB525f9FCf346490bbb3ed28a81C667',
  CHR: '0x8A2279d4A90B6fe1C4B30fa660cC9f926797bAA2',
  CHZ: '0x3506424F91fD33084466F402d5D97f05F8e3b4AF',
  COTI: '0xDDB3422497E61e13543BeA06989C0789117555c5',
  COVAL: '0x3D658390460295FB963f54dC0899cfb1c30776Df',
  CQT: '0xD417144312DbF50465b1C641d016962017Ef6240',
  CRO: '0xA0b73E1Ff0B80914AB6fe0444E65848C4C34450b',
  CRPT: '0x08389495D7456E1951ddF7c3a1314A4bfb646d8B',
  CRV: '0xD533a949740bb3306d119CC777fa900bA034cd52',
  CTSI: '0x491604c0FDF08347Dd1fa4Ee062a822A5DD06B5D',
  CUBE: '0xDf801468a808a32656D2eD2D2d80B72A129739f4',
  CVC: '0x41e5560054824eA6B0732E656E3Ad64E20e94E45',
  CVX: '0x4e3FBD56CD56c3e72c1403e103b45Db9da5B9D2B',
  DAI: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
  DAR: '0x081131434f93063751813C619Ecca9C4dC7862a3',
  DDX: '0x3A880652F47bFaa771908C07Dd8673A787dAEd3A',
  DENT: '0x3597bfD533a99c9aa083587B074434E61Eb0A258',
  DEXT: '0xfB7B4564402E5500dB5bB6d63Ae671302777C75a',
  DIA: '0x84cA8bc7997272c7CfB4D0Cd3D55cd942B3c9419',
  DPI: '0x1494CA1F11D487c2bBe4543E90080AeBa4BA3C2b',
  DYP: '0x961C8c0B1aaD0c0b10a51FeF6a867E3091BCef17',
  ELA: '0xe6fd75ff38Adca4B97FBCD938c86b98772431867',
  ELON: '0x761D38e5ddf6ccf6Cf7c55759d5210750B5D60F3',
  ENJ: '0xF629cBd94d3791C9250152BD8dfBDF380E2a3B9c',
  ENS: '0xC18360217D8F7Ab5e7c516566761Ea12Ce7F9D72',
  ERN: '0xBBc2AE13b23d715c30720F079fcd9B4a74093505',
  EUL: '0xd9Fcd98c322942075A5C3860693e9f4f03AAE07b',
  EURC: '0x1aBaEA1f7C830bD89Acc67eC4af516284b1bC33c',
  FARM: '0xa0246c9032bC3A600820415aE600c6388619A14D',
  FET: '0xaea46A60368A7bD060eec7DF8CBa43b7EF41Ad85',
  FIS: '0xef3A930e1FfFFAcd2fc13434aC81bD278B0ecC8d',
  FORTH: '0x77FbA179C79De5B7653F68b5039Af940AdA60ce0',
  FRAX: '0x853d955aCEf822Db058eb8505911ED77F175b99e',
  FTM: '0x4E15361FD6b4BB609Fa63C81A2be19d873717870',
  FXS: '0x3432B6A60D23Ca0dFCa7761B7ab56459D9C964D0',
  GAL: '0x5fAa989Af96Af85384b8a938c2EdE4A7378D9875',
  GFI: '0xdab396cCF3d84Cf2D07C4454e10C8A6F5b008D2b',
  GHST: '0x3F382DbD960E3a9bbCeaE22651E88158d2791550',
  GLM: '0x7DD9c5Cba05E151C895FDe1CF355C9A1D5DA6429',
  GNO: '0x6810e776880C02933D47DB1b9fc05908e5386b96',
  GODS: '0xccC8cb5229B0ac8069C51fd58367Fd1e622aFD97',
  GRT: '0xc944E90C64B2c07662A292be6244BDf05Cda44a7',
  GTC: '0xDe30da39c46104798bB5aA3fe8B9e0e1F348163F',
  GUSD: '0x056Fd409E1d7A124BD7017459dFEa2F387b6d5Cd',
  HFT: '0xb3999F658C0391d94A37f7FF328F3feC942BcADC',
  HIGH: '0x71Ab77b7dbB4fa7e017BC15090b2163221420282',
  ILV: '0x767FE9EDC9E0dF98E07454847909b5E959D7ca0E',
  IMX: '0xF57e7e7C23978C3cAEC3C3548E3D615c346e79fF',
  INDEX: '0x0954906da0Bf32d5479e25f46056d22f08464cab',
  INJ: '0xe28b3B32B6c345A34Ff64674606124Dd5Aceca30',
  INV: '0x41D5D79431A913C4aE7d69a668ecdfE5fF9DFB68',
  IOTX: '0x6fB3e0A217407EFFf7Ca062D46c26E5d60a14d69',
  JASMY: '0x7420B4b9a0110cdC71fB720908340C03F9Bc03EC',
  JUP: '0x4B1E80cAC91e2216EEb63e29B957eB91Ae9C2Be8',
  KEEP: '0x85Eee30c52B0b379b046Fb0F85F4f3Dc3009aFEC',
  KP3R: '0x1cEB5cB57C4D4E2b2433641b95Dd330A33185A44',
  LCX: '0x037A54AaB062628C9Bbae1FDB1583c195585fe41',
  LDO: '0x5A98FcBEA516Cf06857215779Fd812CA3beF1B32',
  LINK: '0x514910771AF9Ca656af840dff83E8264EcF986CA',
  LOKA: '0x61E90A50137E1F645c9eF4a0d3A4f01477738406',
  LPT: '0x58b6A8A3302369DAEc383334672404Ee733aB239',
  LQTY: '0x6DEA81C8171D0bA574754EF6F8b412F2Ed88c54D',
  LRC: '0xBBbbCA6A901c926F240b89EacB641d8Aec7AEafD',
  LUSD: '0x5f98805A4E8be255a32880FDeC7F6728C6568bA0',
  MANA: '0x0F5D2fB29fb7d3CFeE444a200298f468908cC942',
  MASK: '0x69af81e73A73B40adF4f3d4223Cd9b1ECE623074',
  MATH: '0x08d967bb0134F2d07f7cfb6E246680c53927DD30',
  MATIC: '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
  MCO2: '0xfC98e825A2264D890F9a1e68ed50E1526abCcacD',
  MDT: '0x814e0908b12A99FeCf5BC101bB5d0b8B5cDf7d26',
  METIS: '0x9E32b13ce7f2E80A01932B42553652E053D6ed8e',
  MIM: '0x99D8a9C45b2ecA8864373A26D1459e3Dff1e17F3',
  MIR: '0x09a3EcAFa817268f77BE1283176B946C4ff2E608',
  MLN: '0xec67005c4E498Ec7f55E092bd1d35cbC47C91892',
  MONA: '0x275f5Ad03be0Fa221B4C6649B8AeE09a42D9412A',
  MPL: '0x33349B282065b0284d756F0577FB39c158F935e6',
  MTL: '0xF433089366899D83a9f26A773D59ec7eCF30355e',
  MUSE: '0xB6Ca7399B4F9CA56FC27cBfF44F4d2e4Eef1fc81',
  MXC: '0x5Ca381bBfb58f0092df149bD3D243b08B9a8386e',
  NCT: '0x9E46A38F5DaaBe8683E10793b06749EEF7D733d1',
  NEXO: '0xB62132e35a6c13ee1EE0f84dC5d40bad8d815206',
  NMR: '0x1776e1F26f98b1A5dF9cD347953a26dd3Cb46671',
  OCEAN: '0x967da4048cD07aB37855c090aAF366e4ce1b9F48',
  OGN: '0x8207c1FfC5B6804F6024322CcF34F29c3541Ae26',
  ONEINCH: '0x111111111117dC0aa78b770fA6A738034120C302',
  ORN: '0x0258F474786DdFd37ABCE6df6BBb1Dd5dfC4434a',
  OXT: '0x4575f41308EC1483f3d399aa9a2826d74Da13Deb',
  PAXG: '0x45804880De22913dAFE09f4980848ECE6EcbAf78',
  PERP: '0xbC396689893D065F41bc2C6EcbeE5e0085233447',
  PLA: '0x3a4f40631a4f906c2BaD353Ed06De7A5D3fCb430',
  POLS: '0x83e6f1E41cdd28eAcEB20Cb649155049Fac3D5Aa',
  POLY: '0x9992eC3cF6A55b00978cdDF2b27BC6882d88D1eC',
  POWR: '0x595832F8FC6BF59c85C527fEC3740A1b7a361269',
  PRIME: '0xb23d80f5FefcDDaa212212F028021B41DEd428CF',
  PRQ: '0x362bc847A3a9637d3af6624EeC853618a43ed7D2',
  PYUSD: '0x6c3ea9036406852006290770BEdFcAbA0e23A0e8',
  QNT: '0x4a220E6096B25EADb88358cb44068A3248254675',
  QUICK: '0x6c28AeF8977c9B773996d0e8376d2EE379446F2f',
  RAD: '0x31c8EAcBFFdD875c74b94b077895Bd78CF1E64A3',
  RAI: '0x03ab458634910AaD20eF5f1C8ee96F1D6ac54919',
  RARE: '0xba5BDe662c17e2aDFF1075610382B9B691296350',
  RARI: '0xFca59Cd816aB1eaD66534D82bc21E7515cE441CF',
  REN: '0x408e41876cCCDC0F92210600ef50372656052a38',
  REP: '0x1985365e9f78359a9B6AD760e32412f4a445E862',
  REQ: '0x8f8221aFbB33998d8584A2B05749bA73c37a938a',
  REVV: '0x557B933a7C2c45672B610F8954A3deB39a51A8Ca',
  RLC: '0x607F4C5BB672230e8672085532f7e901544a7375',
  RLY: '0xf1f955016EcbCd7321c7266BccFB96c68ea5E49b',
  RNDR: '0x6De037ef9aD2725EB40118Bb1702EBb27e4Aeb24',
  SHIB: '0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE',
  SNT: '0x744d70FDBE2Ba4CF95131626614a1763DF805B9E',
  SOL: '0xD31a59c85aE9D8edEFeC411D448f90841571b89c',
  SPELL: '0x090185f2135308BaD17527004364eBcC2D37e5F6',
  STORJ: '0xB64ef51C888972c908CFacf59B47C1AfBC0Ab8aC',
  SUPER: '0xe53EC727dbDEB9E2d5456c3be40cFF031AB40A55',
  SUSHI: '0x6B3595068778DD592e39A122f4f5a5cF09C90fE2',
  SWFTC: '0x0bb217E40F8a5Cb79Adf04E1aAb60E5abd0dfC1e',
  SYLO: '0xf293d23BF2CDc05411Ca0edDD588eb1977e8dcd4',
  SYN: '0x0f2D719407FdBeFF09D87557AbB7232601FD9F29',
  TBTC: '0x18084fbA666a33d37592fA2633fD49a74DD93a88',
  TOKE: '0x2e9d63788249371f1DFC918a52f8d799F4a38C94',
  TONE: '0x2Ab6Bb8408ca3199B8Fa6C92d5b455F820Af03c4',
  TRAC: '0xaA7a9CA87d3694B5755f213B5D04094b8d0F0A6F',
  TRB: '0x88dF592F8eb5D7Bd38bFeF7dEb0fBc02cf3778a0',
  TRIBE: '0xc7283b66Eb1EB5FB86327f08e1B5816b0720212B',
  TRU: '0x4C19596f5aAfF459fA38B0f7eD92F11AE6543784',
  TVK: '0xd084B83C305daFD76AE3E1b4E1F1fe2eCcCb3988',
  UNI: '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
  USDC: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  USDT: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  VGX: '0x3C4B6E6e1eA3D4863700D7F76b36B7f3D3f13E3d',
  WAMPL: '0xEDB171C18cE90B633DB442f2A6F72874093b49Ef',
  WBTC: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  WETH: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  XCN: '0xA2cd3D43c775978A96BdBf12d733D5A1ED94fb18',
  XSGD: '0x70e8dE73cE538DA2bEEd35d14187F6959a8ecA96',
  YFI: '0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e',
  YFII: '0xa1d0E215a23d7030842FC67cE582a6aFa3CCaB83'
}

export const  USER_OP_SUPPORTED_OPTIMISM = {
	AAVE:'0x76FB31fb4af56892A25e32cFC43De717950c9278',
	CRV:'0x0994206dfE8De6Ec6920FF4D779B0d950605Fb53',
	DAI:'0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
	ENS:'0x65559aA14915a70190438eF90104769e5E890A00',
	FRAX:'0x2E3D870790dC77A83DD1d18184Acc7439A53f475',
	LDO:'0xFdb794692724153d1488CcdBE0C56c252596735F',
	LINK:'0x350a791Bfc2C21F9Ed5d10980Dad2e2638ffa7f6',
	LUSD:'0xc40F949F8a4e094D1b49a23ea9241D289B7b2819',
	OP:'0x4200000000000000000000000000000000000042',
	PEPE:'0xC1c167CC44f7923cd0062c4370Df962f9DDB16f5',
	PERP:'0x9e1028F5F1D5eDE59748FFceE5532509976840E0',
	RAI:'0x7FB688CCf682d58f86D7e38e03f9D22e7705448B',
	UNI:'0x6fd9d7AD17242c41f7131d257212c54A0e816691',
	USDC:'0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85',
	USDT:'0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
	WBTC:'0x68f180fcCe6836688e9084f035309E29Bf0A2095',
	WETH:'0x4200000000000000000000000000000000000006'
}

export const  USER_OP_SUPPORTED_BSC = {
	AAVE:'0xfb6115445Bff7b52FeB98650C87f44907E58f802',
	ADA:'0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
	AVAX:'0x1ce0c2827e2ef14d5c4f29a091d735a204794041',
	BTCB:'0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
	BUSD:'0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
	CAKE:'0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
	DAI:'0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
	DOGE:'0xba2ae424d960c26247dd6c32edc70b295c744c43',
	DOT:'0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
	ETH:'0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
	FET:'0x031b41e504677879370e9DBcF937283A8691Fa7f',
	FRAX:'0x90C97F71E18723b0Cf0dfa30ee176Ab653E89F40',
	FTM:'0xAD29AbB318791D579433D831ed122aFeAf29dcfe',
	HIGH:'0x5f4Bde007Dc06b867f86EBFE4802e34A1fFEEd63',
	HOOK:'0xa260e12d2b924cb899ae80bb58123ac3fee1e2f0',
	LINK:'0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD',
	MATIC:'0xCC42724C6683B7E57334c4E856f4c9965ED682bD',
	MBOX:'0x3203c9e46ca618c8c1ce5dc67e7e9d75f5da2377',
	MIM:'0xfE19F0B51438fd612f6FD59C1dbB3eA319f433Ba',
	SHIB:'0x2859e4544c4bb03966803b044a93563bd2d0dd4d',
	STG:'0xB0D502E938ed5f4df2E681fE6E419ff29631d62b',
	TRON:'0xce7de646e7208a4ef112cb6ed5038fa6cc6b12e3',
	TWT:'0x4B0F1812e5Df2A09796481Ff14017e6005508003',
	UNI:'0xBf5140A22578168FD562DCcF235E5D43A02ce9B1',
	USDC:'0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
	USDT:'0x55d398326f99059fF775485246999027B3197955',
	WBNB:'0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
	XRP:'0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe'
}

export const  USER_OP_SUPPORTED_POLYGON = {
	
	AAVE:'0xD6DF932A45C0f255f85145f286eA0b292B21C90B',
	AGEUR:'0xE0B52e49357Fd4DAf2c15e02058DCE6BC0057db4',
	ANKR:'0x101A023270368c0D50BFfb62780F4aFd4ea79C35',
	BUSD:'0xdAb529f40E671A1D4bF91361c21bf9f0C9712ab7',
	COMP:'0x8505b9d2254A7Ae468c0E9dd10Ccea3A837aef5c',
	CRV:'0x172370d5Cd63279eFa6d502DAB29171933a610AF',
	DAI:'0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
	DPI:'0x85955046DF4668e1DD369D2DE9f3AEB98DD2A369',
	DYDX:'0x4C3bF0a3DE9524aF68327d1D2558a3B70d17D42a',
	FORT:'0x9ff62d1FC52A907B6DCbA8077c2DDCA6E6a9d3e1',
	FRAX:'0x104592a158490a9228070E0A8e5343B499e125D0',
	GHST:'0x385Eeac5cB85A38A9a07A70c73e0a3271CfB54A7',
	GNS:'0xE5417Af564e4bFDA1c483642db72007871397896',
	GRT:'0x5fe2B58c013d7601147DcdD68C143A77499f5531',
	IOTX:'0xf6372cDb9c1d3674E83842e3800F2A62aC9F3C66',
	LDO:'0xC3C7d422809852031b44ab29EEC9F1EfF2A58756',
	LINK:'0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39',
	MANA:'0xA1c57f48F0Deb89f569dFbE6E2B7f46D33606fD4',
	MKR:'0x6f7C932e7684666C9fd1d44527765433e01fF61d',
	MLN:'0xa9f37D84c856fDa3812ad0519Dad44FA0a3Fe207',
	ONEINCH:'0x9c2C5fd7b07E95EE044DDeba0E97a665F142394f',
	OXT:'0x9880e3dDA13c8e7D4804691A45160102d31F6060',
	RNDR:'0x61299774020dA444Af134c82fa83E3810b309991',
	SHIB:'0x6f8a06447Ff6FcF75d803135a7de15CE88C1d4ec',
	SNX:'0x50B728D8D964fd00C2d0AAD81718b71311feF68a',
	SUSHI:'0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a',
	UNI:'0xb33EaAd8d922B1083446DC23f610c2567fB5180f',
	USDC:'0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359',
	USDCe:'0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
	USDT:'0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
	VOXEL:'0xd0258a3fD00f38aa8090dfee343f10A9D4d30D3F',
	WBTC:'0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
	WETH:'0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619'

	
}

export const  USER_OP_SUPPORTED_BASE = {
	
	DAI:'0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb',
	PRIME:'0xfA980cEd6895AC314E7dE34Ef1bFAE90a5AdD21b',
	TBTC:'0x236aa50979D5f3De3Bd1Eeb40E81137F22ab794b',
	USDbC:'0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
	USDC:'0x833589fcd6edb6e08f4c7c32d4f71b54bda02913',
	WAMPL:'0x489fe42C267fe0366B16b0c39e7AEEf977E841eF',
	WETH:'0x4200000000000000000000000000000000000006'
	
}

export const  USER_OP_SUPPORTED_ARBITRUM = { 

	AAVE:'0xba5DdD1f9d7F570dc94a51479a000E3BCE967196',
	ARB:'0x912ce59144191c1204e64559fe8253a0e49e6548',
	BADGER:'0xBfa641051Ba0a0Ad1b0AcF549a89536A0D76472E',
	BAL:'0x040d1edc9569d4bab2d15287dc5a4f10f56a56b8',
	CBETH:'0x1DEBd73E752bEaF79865Fd6446b0c970EaE7732f',
	COMP:'0x354A6dA3fcde098F8389cad84b0182725c6C91dE',
	CRV:'0x11cDb42B0EB46D95f990BeDD4695A6e3fA034978',
	CTX:'0x84F5c2cFba754E76DD5aE4fB369CfC920425E12b',
	DAI:'0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
	FRAX:'0x17fc002b466eec40dae837fc4be5c67993ddbd6f',
	GMX:'0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a',
	GRT:'0x9623063377AD1B27544C965cCd7342f7EA7e88C7',
	KYBER:'0xe4dddfe67e7164b0fe14e218d80dc4c08edc01cb',
	LDO:'0x13Ad51ed4F1B7e9Dc168d8a00cB3f4dDD85EfA60',
	LINK:'0xf97f4df75117a78c1A5a0DBb814Af92458539FB4',
	LPT:'0x289ba1701C2F088cf0faf8B3705246331cB8A839',
	LUSD:'0x93b346b6BC2548dA6A1E7d98E9a421B42541425b',
	MATIC:'0x561877b6b3DD7651313794e5F2894B2F18bE0766',
	SNX:'0xcBA56Cd8216FCBBF3fA6DF6137F3147cBcA37D60',
	SPELL:'0x3E6648C5a70A150A88bCE65F4aD4d506Fe15d2AF',
	SUSHI:'0xd4d42F0b6DEF4CE0383636770eF773390d85c61A',
	TUSD:'0x4d15a3a2286d883af0aa1b3f21367843fac63e07',
	UNI:'0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0',
	USDC:'0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
	USDCe:'0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
	USDT:'0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
	WBTC:'0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f',
	WETH:'0x82aF49447D8a07e3bd95BD0d56f35241523fBab1'

}

export const  USER_OP_SUPPORTED_AVALANCHE = { 

	BENQI:'0x8729438eb15e2c8b576fcc6aecda6a148776c0f5',
	BUSDe:'0x19860ccb0a68fd4213ab9d8266f7bbf05a8dde98',
	DAIe:'0xd586e7f844cea2f87f50152665bcbc2c279d8d70',
	EURC:'0xC891EB4cbdEFf6e073e859e987815Ed1505c2ACD',
	JOE:'0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd',
	MIM:'0x130966628846BFd36ff31a822705796e8cb8C18D',
	PNG:'0x60781c2586d68229fde47564546784ab3faca982',
	STG:'0x2F6F07CDcf3588944Bf4C42aC74ff24bF56e7590',
	USDC:'0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e',
	USDCe:'0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
	USDT:'0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
	USDTe:'0xc7198437980c041c805a1edcba50c1ce5db95118',
	WAVAX:'0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
	WBTC:'0x50b7545627a5162F82A992c33b87aDc75187B218',
	WETH:'0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB'
	
}