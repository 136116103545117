const YOUR_ANKR_API_KEY = '7935e73be177493393de289db945fd97';

export const BSC_NETWORK = {
  chainId: '0x38', // 56 in decimal
  chainName: 'Binance Smart Chain Mainnet',
  nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB', // 2-6 characters long
      decimals: 18,
  },
  rpcUrls: ['https://bsc-dataseed.binance.org/'],
  blockExplorerUrls: ['https://bscscan.com'],
};

export const POLYGON_NETWORK = {
  chainId: '0x89', // 137 in decimal
  chainName: 'Polygon Mainnet',
  nativeCurrency: {
    name: 'Polygon',
    symbol: 'MATIC', // 2-5 characters long
    decimals: 18,
  },
  rpcUrls: ['https://polygon-rpc.com/'], // Official RPC URL
  blockExplorerUrls: ['https://polygonscan.com'],
};



export const ETHEREUM_NETWORK = {
  chainId: '0x1', // 1 in decimal
  chainName: 'Ethereum Mainnet',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: ['https://mainnet.infura.io/v3/'],
  blockExplorerUrls: ['https://etherscan.io'],
};


export const FANTOM_NETWORK = {
  chainId: '0xfa', // 250 in decimal
  chainName: 'Fantom Opera',
  nativeCurrency: {
    name: 'Fantom',
    symbol: 'FTM',
    decimals: 18,
  },
  rpcUrls: ['https://rpc.ankr.com/fantom/'],
  blockExplorerUrls: ['https://ftmscan.com'],
};


export const AVALANCHE_NETWORK = {
  chainId: '0xa86a', // 43114 in decimal
  chainName: 'Avalanche Network',
  nativeCurrency: {
    name: 'Avalanche',
    symbol: 'AVAX',
    decimals: 18,
  },
  rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
  blockExplorerUrls: ['https://snowtrace.io/'],
};


export const ARBITRUM_NETWORK = {
  chainId: '0xa4b1', // 42161 in decimal
  chainName: 'Arbitrum One',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: ['https://arb1.arbitrum.io/rpc'],
  blockExplorerUrls: ['https://arbiscan.io'],
};


export const ZKSYNC_NETWORK = {
  chainId: '0x144', // 324 in decimal, please verify as zkSync evolves
  chainName: 'zkSync Era Mainnet',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: ['https://mainnet.era.zksync.io'],
  blockExplorerUrls: ['https://explorer.zksync.io/'],
};


export const OPTIMISM_NETWORK = {
  chainId: '0xa', // 10 in decimal
  chainName: 'Optimism',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: ['https://optimism-mainnet.infura.io'],
  blockExplorerUrls: ['https://optimistic.etherscan.io'],
};



export const BASE_NETWORK = {
  chainId: '0x2105', // 8453 in decimal, please verify as zkSync evolves
  chainName: 'Base',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: ['https://developer-access-mainnet.base.org'],
  blockExplorerUrls: ['https://basescan.org'],
};