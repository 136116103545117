import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'

import { WagmiProvider } from 'wagmi'
import { mainnet, bsc, polygon, avalanche , arbitrum, fantom, optimism, zkSync, base } from 'wagmi/chains'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { WEB_URL } from '../credentials/credential'

// 0. Setup queryClient
const queryClient = new QueryClient()

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = 'd4ea28498079e4bde1caeee9e524648e'

// 2. Create wagmiConfig
const metadata = {
  name: 'MevBot',
  description: 'MevBot Platform',
  url: 'https://mevbot.run', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [mainnet, bsc, polygon, avalanche , arbitrum, fantom, optimism, zkSync, base] 



// 3. Create modal





export function Web3ModalProvider({ children }) {

  const value =defaultWagmiConfig({
    chains,
    projectId,
    metadata,
    enableWalletConnect: true,
    enableCoinbase: false,
    enableEIP6963: false,
    enableInjected: true,

  })
  const [config, setConfig] = useState(value)
  useEffect(()=>{
    createShit()

  },[])


  function createShit(){
    let config = value
    let customWallets
    let featuredWalletIds
    let excludeWalletIds
    // console.log("window ethereum: ", window.ethereum)
    // console.log("window agent: ", window.navigator.userAgent)
    const httpsURL = WEB_URL.slice(8)
    const url = encodeURIComponent(WEB_URL)

 if(window.innerWidth < 768 && typeof window.ethereum === 'undefined'){
    
   
    featuredWalletIds= []
    // excludeWalletIds =[
    //    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
    //     '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
    //     'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa',
    //     'a797aa35c0fadbfc1a53e7f675162ed5226968b44a19ee3d24385c64d1d3c393'
    //   ]
 
      customWallets = [
       
       {
         id: 'CoinBase',
         name: 'CoinBase (Mobile Browser Only)',
         image_url: 'https://play-lh.googleusercontent.com/PjoJoG27miSglVBXoXrxBSLveV6e3EeBPpNY55aiUUBM9Q1RCETKCOqdOkX2ZydqVf0',
         mobile_link: `https://go.cb-w.com/dapp?cb_url=${url}`, // Optional - Deeplink or universal
        //  desktop_link: 'https://go.cb-w.com/walletlink?redirect_url=https%3A%2F%2Ftesting-lake-two.vercel.app%2F&wl_url=https%3A%2F%2Fwww.walletlink.org%2F%23%2Flink%3Fid%3D6fafdd2d46a5569e98fee8deaa364c82%26secret%3Dde7e3d2f1ceca2141df0d3e274bef3fffcf8d39eabbea1eb353dc38b49ae8f91%26server%3Dhttps%253A%252F%252Fwww.walletlink.org%26v%3D3.9.1%26chainId%3D1', // Optional - Deeplink
          // app_store: 'https://apps.apple.com/us/app/coinbase-buy-bitcoin-ether/id886427730', // Optional
         // play_store: 'https://play.google.com/Wstore/apps/details?id=com.coinbase.android&hl=en&gl=US' // Optional
   
       },
       {
         id: 'MetaMask (Mobile Only)',
         name: 'MetaMask (Mobile Browser Only)',
         image_url:'https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/1200px-MetaMask_Fox.svg.png',
         mobile_link: `https://metamask.app.link/dapp/${httpsURL}`, // Optional - Deeplink or universal
        //  webapp_link: 'chrome-extension://nkbihfbeogaeaoehlefnkodbefgpgknn/home.html/wc?uri=https://testing-lake-two.vercel.app',
        // //  desktop_link: 'https://go.cb-w.com/walletlink?redirect_url=https%3A%2F%2Ftesting-lake-two.vercel.app%2F&wl_url=https%3A%2F%2Fwww.walletlink.org%2F%23%2Flink%3Fid%3D6fafdd2d46a5569e98fee8deaa364c82%26secret%3Dde7e3d2f1ceca2141df0d3e274bef3fffcf8d39eabbea1eb353dc38b49ae8f91%26server%3Dhttps%253A%252F%252Fwww.walletlink.org%26v%3D3.9.1%26chainId%3D1', // Optional - Deeplink
        //  // app_store: 'https://apps.apple.com/us/app/coinbase-buy-bitcoin-ether/id886427730', // Optional
         // play_store: 'https://play.google.com/Wstore/apps/details?id=com.coinbase.android&hl=en&gl=US' // Optional
   
       },
       {
         id: 'Trust Wallet (Mobile Only)',
         name: 'Trust Wallet (Mobile Browser Only)',
         mobile_link: `https://link.trustwallet.com/open_url?coin_id=60&url=${WEB_URL}`, // Optional - Deeplink or universal
         image_url:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiYUPyaVpa2MB2vGUwaJJG5DoXZQYqzcKKk3wkL9RwTw&s'
         //  webapp_link: 'https://link.trustwallet.com/open_url?coin_id=60&url=https://compound.finance',
        //  desktop_link: 'https://link.trustwallet.com/open_url?coin_id=60&url=https://compound.finance', // Optional - Deeplink
        //  // app_store: 'https://apps.apple.com/us/app/coinbase-buy-bitcoin-ether/id886427730', // Optional
        //  // play_store: 'https://play.google.com/Wstore/apps/details?id=com.coinbase.android&hl=en&gl=US' // Optional
        //  homepage:'https://testing-lake-two.vercel.app'
   
       },
   
     ]
   
   
 
 }else{
  config =  defaultWagmiConfig({
    chains,
    projectId,
    metadata,
    enableWalletConnect: true,
    enableCoinbase: true,
    enableEIP6963: true,
    enableInjected: true,
  
  })
  setConfig(config)
 
 }
   createWeb3Modal({
     wagmiConfig: config,
     projectId,
     // enableAnalytics: true, // Optional - defaults to your Cloud configuration
     // enableOnramp: true, // Optional - false as default
     // enableWalletFeatures : true,
     // excludeWalletIds:[
     //   'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
     //   '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
     //   'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa'
   
     // ],
     customWallets : customWallets,
     featuredWalletIds :featuredWalletIds,
    //  excludeWalletIds: excludeWalletIds
   })
 }

  
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  )
}